;(function () {
  'use strict'

  angular.module('ottomatikStoreManager').component('omDpos', {
    bindings: {
      customer: '<',
      store: '<',
    },
    controller: DposController,
    controllerAs: '$ctrl',
    templateUrl: 'src/components/om-dpos.html',
  })

  function DposController(CustomerService) {
    var $ctrl = this

    $ctrl.$onChanges = function () {
      $ctrl.load()
    }

    $ctrl.load = function (event) {
      if ($ctrl.loading || !$ctrl.customer || !$ctrl.store) {
        return
      }

      $ctrl.loading = true
      CustomerService.getDPosStore($ctrl.customer.customerId, $ctrl.store.storeId)
        .$promise.then((response) => {
          $ctrl.status = response.status
          $ctrl.lastPoll = response.date
        })
        .catch(() => {
          $ctrl.status = null
        })
        .finally(() => {
          $ctrl.loading = false
        })
    }
  }
})()
