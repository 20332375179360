;(function () {
  'use strict'

  var OTTOMATIK_ID = 999999999

  angular
    .module('ottomatikStoreManager.index', ['ottomatikStoreManager.reporting', 'catalog.sellout'])
    .config(IndexConfig)
    .controller('IndexController', IndexController)

  function IndexConfig($stateProvider, $urlRouterProvider, moduleRegisterProvider) {
    $stateProvider
      .state('index', {
        url: '/',
        views: {
          '@': {
            templateUrl: 'src/index/views/index.html',
            controller: 'IndexController',
            controllerAs: 'vm',
          },
        },
        resolve: {
          userCustomer: function (UserService, CustomerService) {
            return CustomerService.getCustomer(UserService.getCustomerId()).$promise
          },
          userRoles: function (UserService) {
            return UserService.getRoles()
          },
        },
      })
      .state('error', {
        url: '/error',
        views: {
          '@': {
            templateUrl: 'src/index/views/error.html',
          },
        },
      })

    $urlRouterProvider.when('', '/')
    $urlRouterProvider.otherwise('/error')

    moduleRegisterProvider.add({
      name: 'Startseite',
      url: 'index',
      materialIcon: 'home',
      accessRoles: ['user', '^minimal-ui'],
    })
  }

  function IndexController(userCustomer, userRoles, CustomerService) {
    var vm = this
    vm.customer = userCustomer

    if (userCustomer.customerId == OTTOMATIK_ID) {
      return
    }

    CustomerService.getStores(userCustomer.customerId).$promise.then((response) => {
      vm.stores = response

      if (response.length == 1) {
        vm.paramsSubAcc = {
          customerId: userCustomer.customerId,
          storeId: response[0].storeId,
          dateFrom: moment().startOf('month').subtract(1, 'month').format('L'),
          dateTo: moment().startOf('month').format('L'),
        }
      }
    })

    vm.showBonuspoints = userRoles.includes('statistics_bonuspoints')
    vm.showCatalogSellout = userRoles.includes('catalog_sellout_index')
    vm.showOrdersStatistic = userRoles.includes('statistics_orders')
    vm.showReporting = true
    vm.showSubscribersStatistic = userRoles.includes('statistics_subacc')
    vm.showUnreadFiles = userRoles.includes('files')
  }
})()
