;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.administration', [])
    .config(function ($stateRegistryProvider, moduleRegisterProvider) {
      var root = {
        abstract: true,
        name: 'administration',
        url: '/administration',
        templateUrl: 'src/administration/views/layout.html',
      }

      var users = {
        name: 'administration.users',
        url: '/:customerId/users?{filter:json}',
        params: {
          customerId: {
            dynamic: true,
            squash: true,
            type: 'int',
            value: null,
          },
          filter: { dynamic: true },
        },
        views: {
          'colLeft@administration': {
            templateUrl: 'src/administration/views/user-list.html',
            controller: 'UserListController',
          },
        },
        resolve: {
          dataCustomers: function (CustomerService) {
            'ngInject'
            return CustomerService.getCustomers({ all: true }).$promise
          },
        },
      }

      var userDetails = {
        name: 'administration.users.details',
        url: '/:memberId/details',
        views: {
          'colRight@administration': {
            templateUrl: 'src/administration/views/user-details.html',
            controller: function ($scope, dataUser, UserAdminService) {
              'ngInject'
              $scope.user = dataUser

              $scope.failedLoginLimit = UserAdminService.getMiscData('failedLoginLimit')
              $scope.failedLoginLockout = UserAdminService.getMiscData('failedLoginLockout')

              $scope.userIsLockedOut =
                dataUser.failedLoginCount != null &&
                dataUser.failedLoginDateTime &&
                dataUser.failedLoginCount >= $scope.failedLoginLimit &&
                moment().unix() - moment(dataUser.failedLoginDateTime).unix() < $scope.failedLoginLockout
              if ($scope.userIsLockedOut) {
                $scope.userLockedOutDateTime = moment
                  .unix(Math.ceil((moment(dataUser.failedLoginDateTime).unix() + $scope.failedLoginLockout) / 60) * 60)
                  .toDate()
              }

              $scope.unlock = function (event) {
                UserAdminService.unlock(dataUser).then((response) => {
                  $scope.user = angular.copy(response, dataUser)
                  $scope.userIsLockedOut = false
                  delete $scope.userLockedOutDateTime
                })
              }
            },
          },
        },
        resolve: {
          dataUser: function ($transition$, UserAdminService) {
            'ngInject'
            return UserAdminService.getUser($transition$.params().memberId)
          },
        },
      }

      var userEdit = {
        name: 'administration.users.edit',
        url: '/:memberId/edit',
        views: {
          'colRight@administration': {
            templateUrl: 'src/administration/views/user-form.html',
            controller: 'UserEditController',
          },
        },
        resolve: {
          dataUser: function ($transition$, UserAdminService) {
            'ngInject'
            return UserAdminService.getUser($transition$.params().memberId)
          },
          dataRoles: function (UserAdminService) {
            'ngInject'
            return UserAdminService.getRoles()
          },
          dataSystems: function ($transition$, CustomerService) {
            'ngInject'
            return CustomerService.getSystems($transition$.params().customerId).$promise
          },
          dataDebitors: function ($transition$, DebitorService) {
            'ngInject'
            return DebitorService.getDebitors($transition$.params().customerId)
          },
          dataStoregroups: function ($transition$, CustomerService) {
            'ngInject'
            return CustomerService.getStoregroups($transition$.params().customerId).$promise
          },
          dataStores: function ($transition$, CustomerService) {
            'ngInject'
            return CustomerService.getStores($transition$.params().customerId).$promise
          },
        },
      }

      var userroles = {
        name: 'administration.userroles',
        url: '/userroles',
        views: {
          'colLeft@administration': {
            templateUrl: 'src/administration/views/userrole-list.html',
            controller: 'RoleListController',
            controllerAs: 'list',
          },
        },
        resolve: {
          roles: function (UserAdminService) {
            'ngInject'
            return UserAdminService.getRoles()
          },
        },
      }

      var userroleNew = {
        name: 'administration.userroles.new',
        url: '/new',
        views: {
          'colRight@administration': {
            templateUrl: 'src/administration/views/userrole-form.html',
            controller: 'RoleFormController',
            controllerAs: 'form',
          },
        },
        resolve: {
          permissions: function (UserAdminService) {
            'ngInject'
            return UserAdminService.getPermissions()
          },
          role: function (UserAdminService) {
            'ngInject'
            return UserAdminService.newRole()
          },
        },
      }

      var userroleDetails = {
        name: 'administration.userroles.details',
        url: '/:roleId',
        views: {
          'colRight@administration': {
            templateUrl: 'src/administration/views/userrole-details.html',
            controller: 'RoleDetailController',
            controllerAs: 'details',
          },
        },
        resolve: {
          role: function ($transition$, UserAdminService) {
            'ngInject'
            return UserAdminService.getRole($transition$.params().roleId)
          },
        },
      }

      var userroleEdit = {
        name: 'administration.userroles.details.edit',
        url: '/edit',
        views: {
          'colRight@administration': {
            templateUrl: 'src/administration/views/userrole-form.html',
            controller: 'RoleFormController',
            controllerAs: 'form',
          },
        },
        resolve: {
          permissions: function (UserAdminService) {
            'ngInject'
            return UserAdminService.getPermissions()
          },
        },
      }

      var oejwt = {
        name: 'administration.oejwt',
        url: '/oejwt',
        views: {
          '@': {
            templateUrl: 'src/administration/views/oejwt.html',
            controller: 'OejwtController',
          },
        },
      }

      var reporting = {
        name: 'administration.reporting',
        url: '/reporting',
        views: {
          '@': {
            templateUrl: 'src/administration/views/reporting.html',
            controller: 'ReportingConfigController',
          },
        },
      }

      $stateRegistryProvider.register(root)

      $stateRegistryProvider.register(users)
      $stateRegistryProvider.register(userDetails)
      $stateRegistryProvider.register(userEdit)

      $stateRegistryProvider.register(userroles)
      $stateRegistryProvider.register(userroleNew)
      $stateRegistryProvider.register(userroleDetails)
      $stateRegistryProvider.register(userroleEdit)

      $stateRegistryProvider.register(oejwt)

      $stateRegistryProvider.register(reporting)

      moduleRegisterProvider.add({
        name: 'Administration',
        materialIcon: 'build',
        accessRoles: ['admin'],
        menu: [
          { name: 'Benutzer', url: 'administration.users' },
          { name: 'Rollen', url: 'administration.userroles' },
          { name: 'OEJWT Schlüssel', url: 'administration.oejwt' },
          { name: 'Benachrichtigungen', url: 'administration.reporting' },
        ],
      })
    })
})()
