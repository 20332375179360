;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.administration').factory('UserAdminService', UserAdminService)

  function UserAdminService(CONFIG, $resource) {
    var service = {
      getUsers: getUsers,
      getUser: getUser,
      editUser: editUser,
      deleteUser: deleteUser,

      getRoles: getRoles,
      getRole: getRole,
      newRole: newRole,

      getPermissions: getPermissions,

      getMiscData: getMiscData,

      unlock: unlock,
    }

    var User = $resource(CONFIG.API_URL + '/users/:memberId', { memberId: '@member.memberId' })
    var UserAction = $resource(CONFIG.API_URL + '/users/:memberId/:action', { memberId: '@member.memberId' })
    var Role = $resource(CONFIG.API_URL + '/users/roles/:roleId', { roleId: '@roleId' })
    var Permission = $resource(CONFIG.API_URL + '/users/permissions/:permissionId')
    var MiscData = {}

    return service

    function getUsers(customerId) {
      return User.query({ customerId }, saveHeaderData).$promise
    }

    function getUser(memberId) {
      return User.get({ memberId }, saveHeaderData).$promise
    }

    function editUser(memberId, user) {
      return User.save({ memberId }, user).$promise
    }

    function deleteUser(memberId) {
      return User.delete({ memberId }).$promise
    }

    function getRoles() {
      return Role.query().$promise
    }

    function getRole(roleId) {
      return Role.get({ roleId }).$promise
    }

    function newRole(data) {
      return new Role(data)
    }

    function getPermissions() {
      return Permission.query().$promise
    }

    function saveHeaderData(value, responseHeaders, status, statusText) {
      var misc = responseHeaders('Misc-Data')
      if (misc) {
        MiscData = JSON.parse(misc)
      }
    }

    function getMiscData(key, defaultValue) {
      if (key) {
        if (Object.hasOwnProperty.call(MiscData, key)) {
          return MiscData[key]
        }
        return defaultValue
      }
      return MiscData
    }

    function unlock(user) {
      return UserAction.update({ memberId: user.member.memberId, action: 'unlock' }, null).$promise
    }
  }
})()
