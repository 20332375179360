;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.statistics')
    .controller('StatisticsOpenemmController', StatisticsOpenemmController)

  function StatisticsOpenemmController(
    CONFIG,
    $filter,
    $mdDialog,
    $mdSidenav,
    $scope,
    helperService,
    StatisticService,
    UserService
  ) {
    var sidenavId = 'newsletter-openemm'

    $scope.loading = false
    $scope.loadSummaryStatistic = true

    $scope.load = function () {
      if ($scope.loading) {
        return
      }

      if (helperService.dateDiffDays($scope.filter.dates.from, $scope.filter.dates.to) > 91) {
        $mdDialog.show(
          $mdDialog
            .alert()
            .title('Achtung')
            .textContent('Es dürfen maximal 3 Monate abgefragt werden.')
            .ok('Verstanden')
        )
        return
      }

      var params = {
        customerId: $scope.filter.customerId,
        sendAfter: $filter('date')($scope.filter.dates.from),
        sendBefore: $filter('date')($scope.filter.dates.to),
        limit: $scope.filter.limit,
        page: $scope.filter.page,
        noStats: $scope.loadSummaryStatistic ? undefined : true,
      }

      $scope.loading = true
      StatisticService.getOpenEmmStatistics(params)
        .then(function (response) {
          $scope.mailings = response.data
          $scope.paginationCount = response.paginationCount

          if (params.noStats) {
            delete $scope.chart
          } else {
            $scope.chart = createChart($scope.mailings)
          }
        })
        .finally(function () {
          $scope.loading = false
        })
    }

    $scope.editData = function (event, mailing) {
      if (!$scope.showEditButton) {
        return
      }
      var params = {
        customerId: $scope.filter.customerId,
        mailingId: mailing.id,
      }
      $mdDialog
        .show({
          templateUrl: 'src/statistics/views/openemm-dialog.html',
          targetEvent: event,
          focusOnOpen: false,
          controller: function ($filter, $mdDialog) {
            var $ctrl = this
            $ctrl.loading = false

            $ctrl.close = function () {
              $mdDialog.cancel()
            }

            $ctrl.save = function () {
              if ($ctrl.loading) {
                return
              }
              $ctrl.loading = true

              var adjustment = angular.copy($ctrl.adjustment)
              if (adjustment.sendDate && adjustment.sendTime) {
                adjustment.sendDate =
                  $filter('date')(adjustment.sendDate, 'yyyy-MM-dd') + $filter('date')(adjustment.sendTime, ' HH:mm:ss')
              }
              delete adjustment.sendTime
              adjustment.validFrom = $filter('date')(adjustment.validFrom, 'yyyy-MM-dd')
              adjustment.validTo = $filter('date')(adjustment.validTo, 'yyyy-MM-dd')

              adjustment
                .$save(params)
                .then(function () {
                  $mdDialog.hide()
                })
                .finally(function () {
                  $ctrl.loading = false
                })
            }

            $ctrl.calcDeps = function () {
              if (
                $ctrl.adjustment.statisticOpenerMeasure != null ||
                $ctrl.adjustment.statisticOpenerInvisible != null
              ) {
                $ctrl.adjustment.statisticOpenerTotal =
                  $ctrl.adjustment.statisticOpenerMeasure + $ctrl.adjustment.statisticOpenerInvisible
              } else {
                $ctrl.adjustment.statisticOpenerTotal = null
              }
              $ctrl.adjustment.statisticOpenerPc = $ctrl.adjustment.statisticOpenerMeasure
            }

            $ctrl.init = function () {
              $ctrl.adjustment.sendTime = null
              var sendDate = Date.parse($ctrl.adjustment.sendDate)
              if (sendDate) {
                $ctrl.adjustment.sendDate = new Date(sendDate)
                $ctrl.adjustment.sendTime = new Date(sendDate)
              }
              var validFrom = Date.parse($ctrl.adjustment.validFrom)
              if (validFrom) {
                $ctrl.adjustment.validFrom = new Date(validFrom)
              }
              var validTo = Date.parse($ctrl.adjustment.validTo)
              if (validTo) {
                $ctrl.adjustment.validTo = new Date(validTo)
              }
              $ctrl.adjustment.description = $filter('unemoji')($ctrl.mailing.subject)
            }

            $ctrl.init()
          },
          locals: {
            mailing: mailing,
          },
          bindToController: true,
          resolve: {
            adjustment: function () {
              return StatisticService.getOpenEmmMailing(params)
            },
          },
          controllerAs: '$ctrl',
        })
        .then(function () {
          $scope.load()
        })
    }

    $scope.showMore = function (event, mailing) {
      mailing = angular.copy(mailing)
      mailing.previewUrl =
        CONFIG.API_URL + '/customers/' + $scope.filter.customerId + '/newsletter/' + mailing.id + '/preview/openemm'

      mailing.charts = {
        generate: function (stats) {
          if (Object.keys(stats || {}).length === 0) {
            return
          }

          this.overview = {
            type: 'BarChart',
            data: new google.visualization.DataTable(),
            options: {
              backgroundColor: 'transparent',
              chartArea: {
                height: 250,
              },
              fontName: 'Roboto',
              fontSize: 14,
              height: 300,
              legend: {
                position: 'none',
              },
              tooltip: {
                trigger: 'none',
              },
              vAxis: {
                textStyle: {
                  fontSize: 9,
                },
              },
            },
          }

          this.overview.data.addColumn('string', 'Kennzahl')
          this.overview.data.addColumn('number', 'Wert')
          this.overview.data.addRow(['Versendete E-Mails', stats['statistic.mails.sent']])
          this.overview.data.addRow(['Angenommene E-Mails', stats['statistic.mails.delivered']])
          this.overview.data.addRow(['Öffner (gemessen)', stats['statistic.opener.measure']])
          this.overview.data.addRow(['Öffner (unsichtbar)', stats['statistic.opener.invisible']])
          this.overview.data.addRow(['Öffner (gesamt)', stats['statistic.opener.total']])
          this.overview.data.addRow(['Klicker', stats['statistic.clicker']])
          this.overview.data.addRow(['Abmeldungen', stats['statistic.Opt_Outs']])
          // this.overview.data.addRow(['Hardbounces', stats['statistic.bounces.hardbounce']])
          this.overview.data.addRow(['Ohne Annahmebestätigung', stats['report.softbounces.undeliverable']])
          this.overview.view = new google.visualization.DataView(this.overview.data)
          this.overview.view.setColumns([0, 1, { sourceColumn: 1, role: 'annotation' }])

          this.opener = {
            type: 'PieChart',
            data: new google.visualization.DataTable(),
            options: {
              backgroundColor: 'transparent',
              chartArea: {
                width: '100%',
                height: 250,
              },
              fontName: 'Roboto',
              fontSize: 14,
              height: 300,
              legend: {
                alignment: 'center',
              },
              pieHole: 0.5,
              pieSliceTextStyle: {
                fontSize: 12,
              },
              sliceVisibilityThreshold: 0,
              tooltip: {
                showColorCode: true,
              },
            },
          }

          this.opener.data.addColumn('string', 'Typ')
          this.opener.data.addColumn('number', 'Öffner')
          this.opener.data.addRow(['Desktop', stats['statistic.opener.pc']])
          this.opener.data.addRow(['Tablet', stats['statistic.opener.tablet']])
          this.opener.data.addRow(['Mobile', stats['statistic.opener.mobile']])
          // this.opener.data.addRow(['Smart TV', stats['statistic.opener.smarttv']])
          // this.opener.data.addRow(['mehrere Geräte', stats['statistic.opener.multiple']])
        },
      }
      mailing.charts.generate(mailing.stats)

      $scope.$root.mailing = mailing
      $mdSidenav(sidenavId).open()
    }

    $scope.$root.$watch('isSidenavOpenemmOpen', function (isOpen) {
      if (!isOpen) {
        delete $scope.$root.mailing
      }
    })

    $scope.showEditButton = UserService.hasRole('admin') || UserService.hasRole('stats-newsletter-openemm-edit')

    function createChart(mailings) {
      var chart = {
        type: 'LineChart',
        data: {
          cols: [
            { type: 'date', label: 'Datum' },
            { type: 'number', label: 'Anzahl der Empfänger' },
            { type: 'string', role: 'tooltip', p: { html: true } },
          ],
          rows: [],
        },
        options: {
          backgroundColor: 'transparent',
          chartArea: {
            width: '50%',
          },
          fontName: 'Roboto',
          fontSize: 14,
          hAxis: {
            minorGridlines: { count: 0, color: 'transparent' },
          },
          height: 400,
          legend: 'none',
          pointSize: 5,
          title: 'Empfänger pro Newsletter',
          titleTextStyle: {
            fontSize: 16,
          },
          tooltip: {
            isHtml: true,
          },
        },
      }

      var uniqueDates = new Set()

      $filter('orderBy')(mailings, 'sendDate')
        .filter((mailing) => mailing.status != 'sending')
        .forEach((mailing) => {
          var sendDate = new Date(mailing.sendDate)
          var recipientsCount = mailing.stats['statistic.mails.sent']

          var tooltip =
            '<div class="google-visualization-tooltip-item-list">' +
            '<div class="google-visualization-tooltip-item"><b>' +
            $filter('date')(sendDate, 'dd.MM.yyyy HH:mm') +
            '</b></div>' +
            '<div class="google-visualization-tooltip-item">' +
            mailing.subject +
            '</div>' +
            '<div class="google-visualization-tooltip-item">Empfänger: ' +
            $filter('number')(recipientsCount) +
            '</div>' +
            '</div>'

          chart.data.rows.push({
            c: [{ v: sendDate }, { v: recipientsCount }, { v: tooltip }],
          })

          uniqueDates.add($filter('date')(sendDate, 'yyyy-MM-dd'))
        })

      chart.options.hAxis.ticks = Array.from(uniqueDates).map((dateString) => {
        return {
          v: new Date(dateString + ' 12:00:00'),
          f: $filter('date')(dateString),
        }
      })

      chart.show = true

      return chart
    }
  }
})()
