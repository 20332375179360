'use strict';

(function () {
  angular
    .module('ottomatikStoreManager.management')
    .controller('CustomerNewController', function ($scope, $state, $filter, CustomerService, dataSystems, currencies) {
      $scope.systems = dataSystems.filter(function (system) {
        return system.systemId > 0
      })
      $scope.currencies = currencies
      $scope.formTitle = 'Neuer Kunde'

      $scope.reset = function () {
        $scope.customer = {
          active: false,
          options: {}
        }
        $scope.onlineSince = undefined

        if ($scope.customerForm) {
          $scope.customerForm.$setPristine()
          $scope.customerForm.$setUntouched()
        }
      }

      $scope.save = function () {
        var customer = $scope.customer
        customer.onlineSince = $filter('date')($scope.onlineSince, 'yyyy-MM-dd')

        CustomerService.createCustomer(customer).$promise.then(function (customer) {
          if (customer.customerId != null) {
            $state.go('^.details', { customerId: customer.customerId }, { reload: true })
          }
        })
      }

      $scope.reset()
    })
    .controller('CustomerEditController', function ($scope, $state, $filter, CustomerService, dataSystems, dataCustomer, currencies) {
      if (undefined !== dataCustomer.options.length) {
        dataCustomer.options = {}
      }
      dataCustomer.systems = []
      dataCustomer.customerSystemAssociations.forEach(function (association) {
        dataCustomer.systems.push(association.system.systemId)
      })
      var customerBackup = angular.copy(dataCustomer)
      $scope.formTitle = 'Kunde bearbeiten'
      $scope.systems = dataSystems.filter(function (system) {
        return system.systemId > 0
      })
      $scope.currencies = currencies

      $scope.reset = function () {
        $scope.customer = angular.copy(customerBackup)
        $scope.onlineSince = new Date($scope.customer.onlineSince)

        if ($scope.customerForm) {
          $scope.customerForm.$setPristine()
          $scope.customerForm.$setUntouched()
        }
      }

      $scope.save = function () {
        var customer = $scope.customer
        customer.onlineSince = $filter('date')($scope.onlineSince, 'yyyy-MM-dd')

        CustomerService.editCustomer($scope.customer.customerId, customer).$promise.then(function (customer) {
          $state.go('^.details', null, { reload: true })
        })
      }

      $scope.reset()
    })
    .controller('CustomerOptionsController', function ($scope, $state, $mdDialog, CustomerService, dataCustomer, dataOptions) {
      var _showOnce = {}
      var optionsBackup = angular.copy(dataOptions)
      $scope.customer = dataCustomer
      $scope.customerId = $scope.customer.customerId
      $scope.option = dataOptions

      $scope.showOnce = function (index, name) {
        if (!Object.prototype.hasOwnProperty.call(_showOnce, name)) {
          _showOnce[name] = index
        }
        return _showOnce[name] === index
      }

      $scope.testMagentoSettings = function () {
        CustomerService.testOptions($scope.customerId).$promise.then(function (response) {
          var title, message
          if (response.instanceAdmin.testResult && response.instanceMirror.testResult) {
            title = 'API-Test erfolgreich'
            message = 'Das OSM konnte sich mit diesen Einstellungen erfolgreich per OAuth an allen Magento-Instanzen authentifizieren und die REST-API abfragen.'
          } else {
            var testStatus = 'Admin-Host: ' + (response.instanceAdmin.testResult ? 'OK' : 'Fehler (' + response.instanceAdmin.message + ')!')
            testStatus += ' Mirror-Host: ' + (response.instanceMirror.testResult ? 'OK' : 'Fehler (' + response.instanceMirror.message + ')!')
            title = 'API-Test fehlgeschlagen!'
            message = 'Die Einstellungen sind fehlerhaft! Status: ' + testStatus
          }

          var alert = $mdDialog.alert().title(title).ariaLabel(title).textContent(message).ok('OK')
          $mdDialog.show(alert).finally(function () {
            alert = undefined
          })
        })
      }

      $scope.servicecenter = function (systemId, sc) {
        CustomerService.setOptions('servicecenter', $scope.customerId, { systemId: systemId, sc: sc }).$promise.then(null, function () {
          var title = 'Service Center setzen'
          var message = 'Der Status konnte nicht gesetzt werden!'
          var alert = $mdDialog.alert().title(title).ariaLabel(title).textContent(message).ok('OK')
          $mdDialog.show(alert).finally(function () {
            alert = undefined
          })
        })
      }

      $scope.reset = function () {
        $scope.option = angular.copy(optionsBackup)
        $scope.customerMagentoForm.$setPristine()
        $scope.customerMagentoForm.$setUntouched()
      }

      $scope.save = function () {
        CustomerService.setOptions('all', $scope.customerId, $scope.option).$promise.then(function (response) {
          $state.go('^.details')
        })
      }
    })
    .controller('MemberListController', function ($scope, $transition$, $state, $mdDialog, $filter, CustomerService, dataMembers) {
      $scope.customerId = $transition$.params().customerId
      $scope.allMembers = dataMembers

      $scope.memberDelete = function (event, member) {
        var confirm = $mdDialog.confirm()
          .targetEvent(event)
          .title('Ansprechpartner löschen?')
          .htmlContent('<center>Soll der Ansprechpartner<br><br><b>' + member.name + '</b><br><br>' + (member.user ? '<u>und Benutzer</u> ' : '') + 'wirklich gelöscht werden?</center>')
          .ok('Ja')
          .cancel('Nein')

        return $mdDialog.show(confirm).then(() => {
          return CustomerService.deleteMember($scope.customerId, member.memberId).$promise.then((response) => {
            if (response.deleted) {
              $state.go('.', { filter: $scope.filter.getStateParams() }, { reload: true })
            }
            return response
          })
        })
      }

      $scope.userEdit = function (event, member) {
        $state.go('administration.users.edit', {
          filter: $scope.filter.getStateParams(),
          customerId: $scope.customerId,
          memberId: member.memberId,
        })
      }

      $scope.filter = {
        member: angular.copy($transition$.params().filter) || {},
        clear: function () {
          this.member = {}
        },
        updateMembers: function () {
          $scope.members = $filter('filter')($scope.allMembers, (member) => {
            var show = true
            show = show && (!this.member.id || member.memberId === parseInt(this.member.id))
            show = show && (!this.member.name || member.name.toLowerCase().indexOf(this.member.name.toLowerCase()) > -1 || (member.addition || '').toLowerCase().indexOf(this.member.name.toLowerCase()) > -1)
            show = show && (!this.member.email || member.email.toLowerCase().indexOf(this.member.email.toLowerCase()) > -1)
            return show
          })
          $state.go('.', { filter: this.getStateParams() })
        },
        getStateParams: function () {
          var params = angular.copy(this.member)
          Object.keys(params).forEach((key) => {
            if (!params[key]) {
              delete params[key]
            }
          })
          if (angular.equals(params, {})) {
            return undefined
          }
          return params
        },
      }

      $scope.filter.updateMembers()
    })
    .controller('MemberNewController', function ($scope, $state, $transition$, CustomerService) {
      $scope.formTitle = 'Neuer Ansprechpartner'
      $scope.customerId = $transition$.params().customerId
      $scope.member = {}

      $scope.reset = function () {
        $scope.member = {}
        $scope.memberForm.$setPristine()
        $scope.memberForm.$setUntouched()
      }

      $scope.save = function () {
        CustomerService.createMember($scope.customerId, $scope.member).$promise.then(function (member) {
          if (member.memberId != null) {
            $state.go('^.details', { memberId: member.memberId }, { reload: true })
          }
        })
      }
    })
    .controller('MemberEditController', function ($scope, $transition$, $state, CustomerService, dataMember) {
      var memberBackup = angular.copy(dataMember)
      $scope.formTitle = 'Ansprechpartner bearbeiten'
      $scope.customerId = $transition$.params().customerId
      $scope.member = dataMember

      $scope.reset = function () {
        $scope.member = angular.copy(memberBackup)
        $scope.memberForm.$setPristine()
        $scope.memberForm.$setUntouched()
      }

      $scope.save = function () {
        CustomerService.editMember($scope.customerId, $scope.member.memberId, $scope.member).$promise.then(function (member) {
          if (member.memberId != null) {
            $state.go('^.details', null, { reload: true })
          }
        })
      }
    })
    .controller('MemberUserNewController', function ($scope, $transition$, $state, Notification, ClipboardService, CustomerService, PasswordService, helperService, dataMember, dataRoles, dataSystems) {
      $scope.formTitle = 'Benutzer-Zugang erstellen :: ' + dataMember.forename + ' ' + dataMember.lastname + (dataMember.addition !== null ? ' (' + dataMember.addition + ')' : '')
      $scope.customerId = $transition$.params().customerId
      $scope.member = dataMember
      $scope.user = {}
      $scope.user.state = true
      $scope.roles = dataRoles
      $scope.systems = dataSystems
      $scope.setRefUser = function () { helperService.setRefUser($scope.user, $scope.systems) }
      $scope.selectUserSystem = function () { helperService.selectUserSystem($scope.user) }

      $scope.generatePassword = function () {
        var password = PasswordService.generateRandomPassword(8, { incUpper: false, incSymbols: false })
        $scope.user.password = password
        $scope.user.passwordVerify = password
        var message = '<p><b>Passwort:</b> <i>' + password + '</i></p>'
        if (ClipboardService.copy(password)) {
          message += '<p>In die Zwischenablage kopiert!</p>'
        }
        Notification.info(message)
      }

      $scope.save = function () {
        CustomerService.createMemberUser($scope.customerId, $scope.member.memberId, $scope.user).$promise.then(function (user) {
          $state.go('^.^.details', null, { reload: true })
        })
      }
    })
    .controller('StoreListController', function ($scope, $state, $mdDialog, CustomerService, dataCustomer, dataStores) {
      var self = this
      $scope.customer = dataCustomer
      $scope.customerId = $scope.customer.customerId
      $scope.allStores = angular.copy(dataStores)
      $scope.stores = angular.copy(dataStores)
      $scope.hasForeignIdent = dataStores.some(function (store) {
        return store.foreignIdent
      })

      $scope.storesSync = function () {
        var confirm = $mdDialog.confirm()
          .title('Stores synchronisieren')
          .ariaLabel('Stores synchronisieren')
          .textContent('Sollen die Stores von ' + $scope.customer.name + ' synchronisiert werden?')
          .ok('Ja')
          .cancel('Nein')

        $mdDialog.show(confirm).then(function () {
          CustomerService.syncStores($scope.customerId).$promise.then(self.handleSyncResponse)
        })
      }

      $scope.storeSync = function (storeId) {
        var storeName
        angular.forEach($scope.stores, function (store) {
          if (store.storeId === storeId) {
            storeName = store.name
          }
        })
        var confirm = $mdDialog.confirm()
          .title('Store synchronisieren')
          .ariaLabel('Store synchronisieren')
          .textContent('Soll ' + $scope.customer.name + ' ' + storeName + ' (ID ' + storeId + ') synchronisiert werden?')
          .ok('Ja')
          .cancel('Nein')

        $mdDialog.show(confirm).then(function () {
          CustomerService.syncStore($scope.customerId, storeId).$promise.then(function (response) {
            self.handleSyncResponse(response, { name: storeName, id: storeId })
          })
        })
      }

      $scope.filter = {
        store: {
          foreignIdent: {
            value: '',
            propset: ['foreignIdent']
          },
          name: {
            value: '',
            propset: ['name', 'address']
          }
        },
        clear: function () {
          this.store.foreignIdent.value = this.store.name.value = ''
        },
        updateStores: function () {
          var stores = $scope.allStores
          for (var prop in this.store) {
            if (this.store[prop] && this.store[prop].value) {
              var search = this.store[prop]
              stores = stores.filter(function (store) {
                var subset = search.propset.reduce(function (a, p) {
                  a.push(store[p])
                  if (p === 'foreignIdent' && store[p]) {
                    a.push(store[p].replace(/\s/g, ''))
                  }
                  return a
                }, [])
                return subset.findIndex(function (v) {
                  return v !== null ? v.toLowerCase().indexOf(search.value.toLowerCase()) > -1 : false
                }) > -1
              })
            }
          }
          $scope.stores = stores
        }
      }

      self.handleSyncResponse = function (response, options) {
        var success = response.syncCount === response.totalCount ? 2 : (response.syncCount === 0 ? 0 : 1)
        var title = 'Synchronisation ' + (success > 0 ? ((success === 1 ? 'teilweise ' : '') + 'erfolgreich') : 'fehlgeschlagen')

        var message = ''
        if (options === undefined) {
          var wurden = 'wurde' + (response.syncCount !== 1 ? 'n' : '')
          var Stores = 'Store' + (response.syncCount !== 1 ? 's' : '')
          message = 'Es ' + wurden + ' ' + response.syncCount + ' von ' + response.totalCount + ' ' + Stores + ' synchronisiert.'
        } else {
          message = $scope.customer.name + ' ' + options.name + ' (ID ' + options.id + ') ' + (success ? 'wurde erfolgreich synchronisiert.' : 'konnte nicht synchronisiert werden.')
        }

        var alert = $mdDialog.alert().title(title).ariaLabel(title).textContent(message).ok('OK')
        $mdDialog.show(alert).finally(function () {
          $state.go('.', null, { reload: true })
        })
      }
    })
    .controller('StoreFormController', function ($mdDialog, $scope, $state, $transition$, dataStore, CustomerService) {
      $scope.posOptions = [
        'acom',
        'ClickBox',
        'SIDES',
      ]

      var customerId = $transition$.params().customerId
      if (dataStore.appendToStore) {
        dataStore.appendToStore = dataStore.appendToStore.storeId
      }
      $scope.store = angular.copy(dataStore)

      CustomerService.getStores(customerId).$promise.then(function (stores) {
        $scope.stores = stores
      })

      $scope.openDialogAtlasDeliveryAreaSync = function (event) {
        var promise = $mdDialog.show({
          templateUrl: 'src/management/views/dialog.store-deliveryarea-atlas.html',
          targetEvent: event,
          controller: function (StoreService) {
            var vm = this
            vm.atlas = {
              username: '',
              password: '',
            }
            vm.submit = submit
            vm.save = save

            function submit(event) {
              if (document.activeElement.tagName.toLowerCase() == 'input') {
                document.activeElement.blur()
              }

              vm.loading = true
              return StoreService.loadDeliveryAreaFromAtlas(vm.store, vm.atlas)
                .then((response) => {
                  vm.filepath = response.filepath
                })
                .finally(() => {
                  vm.loading = false
                })
            }

            function save(event) {
              vm.loading = true
              return StoreService.saveDeliveryAreaFromAtlas(vm.store, vm.filepath)
                .then((response) => {
                  $mdDialog.hide(response.filepath)
                })
                .finally(() => {
                  vm.loading = false
                })
            }
          },
          controllerAs: '$ctrl',
          locals: {
            store: $scope.store,
          },
          bindToController: true,
        })

        promise.then((newFilepath) => {
          void 0
          $scope.store.deliveryAreaFilepath = newFilepath
        })
      }

      $scope.reset = function () {
        $scope.store = angular.copy(dataStore)
        $scope.storeForm.$setPristine()
        $scope.storeForm.$setUntouched()
      }

      $scope.save = function () {
        var store = angular.copy($scope.store)
        delete store.deliveryAreaFile

        var deliveryAreaFile = $scope.store.deliveryAreaFile

        var promise = CustomerService.editStore(customerId, $scope.store).$promise
        if (deliveryAreaFile) {
          promise = promise.then(() => CustomerService.uploadDeliveryArea(customerId, store.storeId, deliveryAreaFile))
        }

        promise.then(() => {
          $state.go('^.details', null, { reload: true })
        })
      }
    })
    .controller('StoregroupListController', function ($scope, $transition$, $state, $mdDialog, CustomerService, dataStoregroups) {
      $scope.customerId = $transition$.params().customerId
      $scope.storegroups = dataStoregroups

      $scope.storegroupDelete = function (storegroupId) {
        var confirm = $mdDialog.confirm()
          .title('Store-Gruppe löschen')
          .textContent('Soll diese Store-Gruppe wirklich gelöscht werden?')
          .ariaLabel('Store-Gruppe löschen')
          .ok('OK')
          .cancel('Abbrechen')

        $mdDialog.show(confirm).then(function () {
          CustomerService.deleteStoregroup($scope.customerId, storegroupId)
          $state.go('.', null, { reload: true })
        })
      }
    })
    .controller('StoregroupFormController', function ($scope, $state, $transition$, CustomerService, dataStoregroup, dataStores) {
      var self = this
      $scope.customerId = $transition$.params().customerId

      if (dataStoregroup === undefined) { // new storegroup
        dataStoregroup = { storegroupId: 0, stores: [] }
        $scope.formTitle = 'Neue Store-Gruppe'
      } else { // edit storegroup
        $scope.formTitle = 'Store-Gruppe bearbeiten'
      }

      var storegroupBackup = angular.copy(dataStoregroup)
      $scope.storegroup = dataStoregroup
      $scope.storesAll = dataStores

      // init array with selected store IDs
      self.initStoresSelected = function () {
        $scope.storesSelected = []
        for (var i = 0; i < dataStoregroup.stores.length; i++) {
          $scope.storesSelected.push(dataStoregroup.stores[i].storeId)
        }
      }

      $scope.addStores = function () {
        var storesSelectedNew = []
        for (var i = 0; i < $scope.storesAll.length; i++) {
          storesSelectedNew.push($scope.storesAll[i].storeId)
        }
        $scope.storesSelected = storesSelectedNew
      }

      $scope.removeStores = function () {
        $scope.storesSelected = []
      }

      $scope.addStore = function (storeId) {
        $scope.storesSelected.push(storeId)
      }

      $scope.removeStore = function (storeId) {
        var storesSelectedNew = []
        for (var i = 0; i < $scope.storesSelected.length; i++) {
          if ($scope.storesSelected[i] !== storeId) {
            storesSelectedNew.push($scope.storesSelected[i])
          }
        }
        $scope.storesSelected = storesSelectedNew
      }

      $scope.reset = function () {
        $scope.storegroup = angular.copy(storegroupBackup)
        self.initStoresSelected()
        $scope.storegroupForm.$setPristine()
        $scope.storegroupForm.$setUntouched()
      }

      $scope.save = function () {
        var storegroup = $scope.storegroup
        storegroup.stores = $scope.storesSelected

        if (storegroup.storegroupId === 0) { // new storegroup
          CustomerService.createStoregroup($scope.customerId, storegroup).$promise.then(function (storegroup) {
            if (storegroup.storegroupId != null) {
              $state.go('^.details', { storegroupId: storegroup.storegroupId }, { reload: true })
            }
          })
        } else { // edit storegroup
          CustomerService.editStoregroup($scope.customerId, storegroup.storegroupId, storegroup).$promise.then(function (storegroup) {
            if (storegroup.storegroupId != null) {
              $state.go('^.details', null, { reload: true })
            }
          })
        }
      }

      self.initStoresSelected()
    })
})()
