;(function () {
  'use strict'

  angular.module('catalog.sellout', []).component('omCatalogSellout', {
    templateUrl: 'src/components/om-catalog-sellout.html',
    bindings: {
      customer: '<',
      stores: '<?',
      titlePrefix: '@?',
      triggerLoad: '<?',
    },
    controller: CatalogSellout,
  })

  function CatalogSellout($element, $filter, $mdSidenav, $rootScope, CatalogService, UserService) {
    var ctrl = this
    ctrl.availableAgain = availableAgain
    ctrl.hasCheckedItems = hasCheckedItems
    ctrl.isSc = UserService.hasRole(['admin', 'servicecenter'])

    ctrl.$onChanges = function (changes) {
      if (!$element[0].hasAttribute('trigger-load') || (changes.triggerLoad && changes.triggerLoad.currentValue)) {
        load()
      }
    }

    function load() {
      if (ctrl.loading) {
        return
      }
      ctrl.loading = true
      var promise = CatalogService.getSellout(ctrl.customer.customerId, ctrl.stores).$promise
      promise
        .then(function (data) {
          ctrl.data = data
        })
        .finally(function () {
          ctrl.loading = false
        })
    }

    function availableAgain(event, sellout) {
      var selectedItems = $filter('orderBy')(
        sellout.items.filter((item) => item.isChecked),
        'name'
      )
      var report = {
        customer: { customerId: ctrl.customer.customerId },
        store: sellout.store,
        type: 'Artikel Aktivierung',
        caller: $rootScope.globals.currentUser.name,
        note: selectedItems.map((item) => item.name).join('\n'),
      }

      $rootScope.reportingEditReport = report
      $mdSidenav('reporting').open()
    }

    function hasCheckedItems(sellout) {
      return sellout.items.some((item) => item.isChecked)
    }
  }
})()
