;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.files')
    .controller('FilesExplorerController', FilesExplorerController)
    .controller('FilesSearchController', FilesSearchController)

  function FilesExplorerController($mdDialog, $q, $scope, $state, $transitions, FileService) {
    var vm = this
    vm.cd = cd
    vm.download = download
    vm.refresh = refresh
    vm.search = search

    var fileListCache = {}

    // listen for folder change
    var deregisterTransitionHook = $transitions.onSuccess({ to: $state.current.name }, (transition) => {
      updateCwd(transition.params().folder)
      loadFileList()
    })

    $scope.$on('$destroy', function () {
      deregisterTransitionHook()
    })

    $scope.$on('UPDATE_UNREAD_FILES', function () {
      markUnreadFiles(vm.list)
    })

    // initial loading
    updateCwd($state.params.folder)
    loadFileList()

    function cd(event, folder) {
      var path = ''
      if (folder) {
        path = (folder.dir ? folder.dir + '/' : '') + folder.name
      }
      return $state.go('.', { folder: path })
    }

    function download(event, file) {
      return FileService.download(file.dir + '/' + file.name).then(() => {
        file.unread = false
      })
    }

    function generateBreadcrumbs() {
      if (!vm.cwd) {
        return []
      }
      return vm.cwd.split('/').map((dir, i, array) => {
        if (array[i - 1]) {
          array[i] = array[i - 1] + '/' + dir
        }
        return {
          dir: array[i - 1] || '',
          name: dir,
        }
      })
    }

    function loadFileList() {
      var path = vm.cwd
      var promise = fileListCache[path] ? $q.resolve(fileListCache[path]) : loadFileListFromServer(path)
      return promise.then((list) => {
        markUnreadFiles(list)
        vm.list = list
      })
    }

    function loadFileListFromServer(path) {
      return FileService.list(path).then((response) => {
        fileListCache[path] = response
        return angular.copy(response)
      })
    }

    function refresh(event) {
      return loadFileListFromServer(vm.cwd).then((list) => {
        markUnreadFiles(list)
        vm.list = list
      })
    }

    function updateCwd(path) {
      vm.cwd = path || ''
      vm.breadcrumbs = generateBreadcrumbs()
    }

    function markUnreadFiles(list) {
      var unreadFiles = $scope.$root.unreadFiles || []
      list.forEach((entry) => {
        var path = (entry.dir ? entry.dir + '/' : '') + entry.name + (entry.type == 'dir' ? '/' : '')
        entry.unread = unreadFiles.filter((file) => file.startsWith(path)).length > 0
      })
    }

    function search(event) {
      var dialog = {
        templateUrl: 'src/files/views/dialog.search.html',
        targetEvent: event,
        controller: 'FilesSearchController',
        controllerAs: 'vm',
      }
      return $mdDialog.show(dialog).then((object) => {
        switch (object.type) {
          case 'dir':
            cd(null, object)
            break
          case 'file':
            var folderRegex = /^(?<dir>.*?)\/?(?<name>[^\/]+)$/.exec(object.dir)
            if (!folderRegex) {
              break
            }
            cd(null, folderRegex.groups).then(() => {
              download(null, object)
            })
            break
        }
      })
    }
  }

  function FilesSearchController($filter, $mdDialog, FileService) {
    var vm = this
    vm.close = close
    vm.search = search
    vm.select = select

    function close(event) {
      return $mdDialog.cancel()
    }

    function search(text) {
      return FileService.search(text).then((list) => $filter('orderBy')(list, ['type', 'name', 'dir']))
    }

    function select(object) {
      return $mdDialog.hide(object)
    }
  }
})()
