;(function () {
  'use strict'

  var LAST_REPORTS_LIMIT = 5
  var LAST_REPORTS_LIMIT_INC = 5

  var SOURCES = [
    {
      id: 'Reporting',
      title: 'Service Center',
    },
    {
      id: 'Customer Support',
      title: 'Franchisenehmer',
    },
  ]

  var TOPICS = [
    {
      title: 'Artikel',
      storeRequired: true,
      showPinboardDialog: true,
      sub: [
        { title: 'Aktivierung' },
        { title: 'Deaktivierung' },
        { title: 'Preisänderung' },
        { title: 'Diverses', noteRequired: true },
      ],
    },
    {
      title: 'Store',
      storeRequired: true,
      callerRequired: true,
      showPinboardDialog: true,
      sub: [
        { title: 'Öffnungszeiten' },
        { title: 'Öffnung' },
        { title: 'Schließung' },
        { title: 'Liefergebiete' },
        { title: 'Lieferzeit' },
        {
          title: 'Lieferung aktiviert',
          if: function (source) {
            return source === 'Reporting'
          },
        },
        {
          title: 'Lieferung deaktiviert',
          if: function (source) {
            return source === 'Reporting'
          },
        },
        {
          title: 'Selbstabholung aktiviert',
          if: function (source) {
            return source === 'Reporting'
          },
        },
        {
          title: 'Selbstabholung deaktiviert',
          if: function (source) {
            return source === 'Reporting'
          },
        },
        { title: 'Diverses', noteRequired: true },
      ],
    },
    {
      title: 'Bestellung',
      storeRequired: true,
      sub: [
        { title: 'weiterleiten' },
        { title: 'stornieren' },
        { title: 'Nachfrage' },
        { title: 'Diverses', noteRequired: true },
      ],
    },
    {
      title: 'Technik',
      storeRequired: true,
      noteRequired: true,
      sub: [
        { title: 'Fax' },
        { title: 'Intranet' },
        { title: 'Kassensystem' },
        {
          title: 'OSM-Station',
          if: function (source, customer) {
            return customer.name === 'Tele Pizza'
          },
        },
        { title: 'Diverses' },
      ],
    },
    {
      title: 'Tele Points',
      storeRequired: true,
      noteRequired: true,
      if: function (source, customer) {
        return customer.name === 'Tele Pizza'
      },
    },
    {
      title: 'Diverses',
      noteRequired: true,
      sub: [{ title: 'Externes' }, { title: 'Feedback/Kritik' }, { title: 'Rückrufbitte' }],
    },
    {
      title: 'Kundenanfrage',
      customForm: 'customerInquiry',
    },
  ]

  var CUSTOMER_INQUIRY_TYPES = {
    gdpr: 'DSGVO-Anfrage (Datenauskunft)',
    unsubscribe: 'Newsletter-Abmeldung',
    'unsubscribe->noMoreMails': 'auch sonst keine weiteren E-Mails erhalten',
    accountRemoval: 'Kundenkonto löschen',
    denyMailing: 'keine postalische Werbung',
    dataRemoval: 'alle Daten löschen',
  }

  var CUSTOMER_INQUIRY_DATA = {
    date: 'Datum der Anfrage',
    customerFirstname: 'Vorname',
    customerLastname: 'Nachname',
    customerMail: 'E-Mail',
    customerMailMore: 'weitere E-Mail',
    customerAddress: 'Adresse',
    customerPhone: 'Telefonnummer',
  }

  angular
    .module('ottomatikStoreManager.reporting', [])
    .service('ReportingService', ReportingService)
    .controller('ReportingController', ReportingController)
    .run(ReportingInit)

  function ReportingInit($compile, $mdSidenav, $rootScope, ReportingService) {
    $rootScope.$watch('globals.currentUser', watchUser, true)
    function watchUser(currentUser) {
      var button
      if (currentUser) {
        if (ReportingService.isAllowed(['admin', 'servicecenter'])) {
          // Reporting
          button = {
            id: 'reporting-sc',
            source: 'Reporting',
            templateUrl: "'src/reporting/views/global-button-sc.html'",
          }
        } else if (ReportingService.isAllowed(['user'])) {
          // Customer Support
          button = {
            id: 'reporting-customer',
            source: 'Customer Support',
            templateUrl: "'src/reporting/views/global-button-customer.html'",
          }
        }
      }

      // hide reporting for minimal UI
      if (ReportingService.isAllowed(['minimal-ui'])) {
        button = null
      }

      var div = document.querySelector('.reporting-button')
      if (!button) {
        if (div) {
          div.parentNode.removeChild(div)
          document.body.classList.remove('reporting')
        }
        return
      }

      // add global button
      $rootScope.reportingSource = button.source
      div = document.createElement('div')
      div.id = button.id
      div.classList.add('reporting-button')
      div.setAttribute('ng-show', 'globals.currentUser && globals.sessionChecked')
      div.setAttribute('ng-include', button.templateUrl)
      document.body.appendChild(div)
      document.body.classList.add('reporting')

      // create scope
      var $scope = $rootScope.$new()
      $scope.open = function () {
        $mdSidenav('reporting').open()
      }
      $compile(angular.element(div))($scope)

      // add global functionality
      var loading = false
      $rootScope.createReport = function (event, report) {
        if (loading) {
          return
        }
        loading = true
        ReportingService.createReport(report).finally(function () {
          loading = false
        })
      }
    }
  }

  function ReportingService(CONFIG, $filter, $resource, $rootScope, CustomerService, UserService) {
    var service = this

    var Report = $resource(CONFIG.API_URL + '/reporting/:reportId', { reportId: '@reportId', hideGlobalSpinner: true })
    var ReportConfig = $resource(CONFIG.API_URL + '/reporting/config')

    service.getConfig = function () {
      return ReportConfig.get().$promise
    }
    service.isAllowed = function (roles) {
      return $filter('intersection')(roles, UserService.getRoles()).length > 0
    }

    // Customers & Stores
    service.getCustomers = function () {
      return CustomerService.getCustomers().$promise
    }
    service.getStores = function (customerId) {
      return CustomerService.getStores(customerId).$promise
    }

    // Sources & Topics
    service.getSources = function () {
      return angular.copy(SOURCES)
    }
    service.getAllTopics = function () {
      return service.getTopics(null, true)
    }
    service.getTopics = function (customer, all) {
      var source = $rootScope.reportingSource
      return angular.copy(TOPICS).reduce(function (out, topic) {
        if (all || !topic.if || topic.if(source, customer)) {
          out.push({
            title: topic.title,
            value: topic.title,
            storeRequired: Boolean(topic.storeRequired),
            callerRequired: Boolean(topic.callerRequired),
            noteRequired: Boolean(topic.noteRequired),
            showPinboardDialog: Boolean(topic.showPinboardDialog),
            customForm: topic.customForm,
            sub: (topic.sub || []).reduce(function (out, subtopic) {
              if (all || !subtopic.if || subtopic.if(source, customer)) {
                out.push({
                  title: subtopic.title,
                  value: topic.title + ' ' + subtopic.title,
                  group: topic.title,
                  storeRequired: Boolean(topic.storeRequired) || Boolean(subtopic.storeRequired),
                  callerRequired: Boolean(topic.callerRequired) || Boolean(subtopic.callerRequired),
                  noteRequired: Boolean(topic.noteRequired) || Boolean(subtopic.noteRequired),
                  showPinboardDialog: Boolean(topic.showPinboardDialog) || Boolean(subtopic.showPinboardDialog),
                  customForm: topic.customForm || subtopic.customForm,
                })
              }
              return out
            }, []),
          })
        }
        return out
      }, [])
    }

    // Reports
    service.createReport = function (report) {
      return Report.create(report).$promise
    }
    service.updateReport = function (report) {
      return Report.update(report).$promise
    }
    service.deleteReport = function (report) {
      return Report.delete({ reportId: report.reportId }).$promise
    }
    service.getLastReports = function (params) {
      return Report.query(params).$promise
    }
  }

  function ReportingController(
    $mdDialog,
    $q,
    $scope,
    CustomerService,
    PinboardService,
    ReportingService,
    ServicecenterService,
    UserService
  ) {
    var $ctrl = this
    var cacheStoresByCustomer = {}
    var storesAll = UserService.hasRole(['admin', 'storesall'])

    $ctrl.canDelete = UserService.hasRole('reporting-delete')
    $ctrl.canEdit = UserService.hasRole('reporting-edit')
    $ctrl.customerSelect = onCustomerSelect
    $ctrl.delete = reportDelete
    $ctrl.edit = reportEdit
    $ctrl.init = init
    $ctrl.loadMore = loadMoreReports
    $ctrl.reset = reset
    $ctrl.storeSelect = onStoreSelect
    $ctrl.submit = formSubmit
    $ctrl.topicSelect = onTopicSelect

    $scope.$root.$watch('isSidenavReportingOpen', (isOpen) => {
      init()
      if (isOpen && !$scope.$root.reportingEditReport) {
        loadLastReports()
      }
    })

    if (storesAll) {
      loadCustomers()
    } else {
      $ctrl.customerId = UserService.getCustomerId()
      CustomerService.getCustomer($ctrl.customerId).$promise.then((response) => {
        onCustomerSelect($ctrl.customerId, response)
      })
    }

    init()

    function checkPinboardForTopic() {
      if (!$ctrl.pinboard || !$ctrl.report.type) {
        return
      }
      var filteredPinboard = $ctrl.pinboard.filter((entry) => $ctrl.report.type.startsWith(entry.topic))
      if (filteredPinboard.length > 0) {
        openPinboardDialog(filteredPinboard)
      }
    }

    function createNoteForCustomerInquiry() {
      var note = ''

      var types = Object.entries(CUSTOMER_INQUIRY_TYPES).reduce((list, keyValue) => {
        var parent = null
        var key = keyValue[0]
        var name = keyValue[1]
        if (key.indexOf('->') != -1) {
          parent = key.split('->')[0]
          key = key.split('->')[1]
        }
        if ((!parent || $ctrl.customerInquiry[parent]) && $ctrl.customerInquiry[key]) {
          list.push(name)
        }
        return list
      }, [])

      var data = Object.entries(CUSTOMER_INQUIRY_DATA).reduce((list, keyValue) => {
        var key = keyValue[0]
        var name = keyValue[1]
        var value = $ctrl.customerInquiry[key]
        if (value) {
          if (key == 'date') {
            value = moment(value).format('L')
          }
          list.push('*' + name + ':* ' + value)
        }
        return list
      }, [])

      if (types.length) {
        note += '*Anfrage:* ' + types.join(', ') + '\n\n'
      }
      note += data.join('\n')
      if ($ctrl.customerInquiry.done) {
        note += '\n\n*ERLEDIGT*'
        if ($ctrl.customerInquiry.doneDate) {
          note += ' am ' + moment($ctrl.customerInquiry.doneDate).format('L')
        }
        if ($ctrl.customerInquiry.doneUser) {
          note += ' von ' + $ctrl.customerInquiry.doneUser
        }
      }

      return note.trim()
    }

    function formSubmit() {
      if ($ctrl.loading) {
        return $q.reject()
      }

      var report = angular.copy($ctrl.report)
      report.customerId = $ctrl.customerId
      report.storeId = $ctrl.storeId
      report.source = $scope.$root.reportingSource

      if ($ctrl.customerInquiry) {
        delete report.caller
        report.note = createNoteForCustomerInquiry()
        report.custom = JSON.stringify({ customerInquiry: $ctrl.customerInquiry })
      }

      $ctrl.loading = true
      var promise = report.reportId ? ReportingService.updateReport(report) : ReportingService.createReport(report)
      return promise
        .then(() => {
          reset(true)
          loadLastReports()
        })
        .finally(() => {
          $ctrl.loading = false
        })
    }

    function init() {
      if (storesAll) {
        $ctrl.customerId = undefined
        $ctrl.customer = undefined
      }
      if ($ctrl.stores && $ctrl.stores.length != 1) {
        $ctrl.storeId = undefined
        $ctrl.store = undefined
      }
      $ctrl.lastReports = undefined
      $ctrl.lastReportsLimit = LAST_REPORTS_LIMIT
      $ctrl.pinboard = undefined
      reset(true)
      loadLastReports()

      if ($scope.$root.reportingEditReport) {
        if (storesAll) {
          selectCustomer($scope.$root.reportingEditReport.customer)
        } else {
          editReportFromRoot()
        }
      }
    }

    function loadCustomers() {
      return ReportingService.getCustomers().then((response) => {
        $ctrl.customers = response
        return response
      })
    }

    function loadLastReports() {
      if (!$ctrl.customerId) {
        $ctrl.lastReports = undefined
        return $q.resolve()
      }

      var params = {
        customerId: $ctrl.customerId,
        storeId: $ctrl.storeId,
        type: $ctrl.report.type,
        limit: $ctrl.lastReportsLimit,
      }

      $ctrl.lastReportsLoading = true
      return ReportingService.getLastReports(params)
        .then((response) => {
          $ctrl.lastReports = response
          return response
        })
        .finally(() => {
          $ctrl.lastReportsLoading = false
        })
    }

    function loadMoreReports() {
      $ctrl.lastReportsLimit += LAST_REPORTS_LIMIT_INC
      return loadLastReports()
    }

    function loadPinboard() {
      if (!$ctrl.customerId || $scope.$root.reportingSource != 'Reporting') {
        return $q.reject()
      }

      var params = {
        active: true,
        customerId: $ctrl.customerId,
        storeId: $ctrl.storeId || undefined,
      }

      $ctrl.loadingPinboard = true
      return PinboardService.getAll(params)
        .then((response) => {
          $ctrl.pinboard = response
          checkPinboardForTopic()
          return response
        })
        .finally(() => {
          $ctrl.loadingPinboard = false
        })
    }

    function loadStores(customerId) {
      var cached = cacheStoresByCustomer[customerId]
      var promise = cached ? $q.resolve(cached) : ReportingService.getStores(customerId)

      return promise.then((response) => {
        if (!cached) {
          cacheStoresByCustomer[customerId] = response
        }
        $ctrl.stores = response
        return response
      })
    }

    function onCustomerSelect(customerId, customer) {
      if (angular.equals([customerId, customer], [$ctrl.customerId, $ctrl.customer])) {
        return
      }

      selectCustomer(customer)
      $ctrl.stores = []
      $ctrl.store = undefined
      $ctrl.storeContacts = undefined
      reset(true)

      if (!customer) {
        selectStore()
        loadLastReports()
        loadPinboard()
        return
      }

      $ctrl.topics = ReportingService.getTopics(customer)
      loadStores(customerId).then((stores) => {
        if ($scope.$root.reportingEditReport) {
          editReportFromRoot()
          return
        }

        if (stores.length == 1) {
          selectStore(stores[0])
        } else {
          selectStore()
        }
        loadLastReports()
        loadPinboard()
      })
    }

    function onStoreSelect(storeId, store) {
      if (angular.equals([storeId, store], [$ctrl.storeId, $ctrl.store])) {
        return
      }

      selectStore(store)
      loadLastReports()
      loadPinboard()
    }

    function onTopicSelect() {
      selectTopic($ctrl.topic)
      loadLastReports()
      checkPinboardForTopic()
    }

    function openPinboardDialog(pinboard) {
      var event = new Event('FakeEvent')
      var element = document.querySelector('.main')
      element.dispatchEvent(event)
      return $mdDialog.show({
        templateUrl: 'src/reporting/views/dialog.pinboard.html',
        targetEvent: event,
        controller: ($scope, pinboard) => {
          $scope.pinboard = pinboard
          $scope.closeDialog = () => $mdDialog.hide()
        },
        locals: {
          pinboard: pinboard,
        },
      })
    }

    function reportDelete(report) {
      var dialog = $mdDialog
        .confirm()
        .title('Meldung #' + report.reportId)
        .htmlContent('Meldung zum Thema <b><q>' + report.type + '</q></b> löschen?')
        .ok('Ja, löschen')
        .cancel('Nein, abbrechen')
      return $mdDialog.show(dialog).then(() => {
        return ReportingService.deleteReport(report).then(loadLastReports)
      })
    }

    function reportEdit(report) {
      selectStore(report.store)
      $ctrl.topic = $ctrl.topics.reduce((out, topic) => {
        if (out) {
          return out
        }
        if (report.type == topic.value) {
          return topic
        }
        return topic.sub.reduce((out, subtopic) => {
          if (report.type == subtopic.value) {
            return subtopic
          }
          return out
        }, null)
      }, null)
      $ctrl.report = {
        reportId: report.reportId,
        type: report.type,
        caller: report.caller,
        note: report.note,
      }
      if (report.custom) {
        Object.assign($ctrl, JSON.parse(report.custom))
      }

      setTimeout(() => {
        if ($ctrl.topic.customForm) {
          document.querySelector('#reporting #customForm').scrollIntoView(true)
        } else {
          document.querySelector('#reportingNote').focus()
        }
      })
    }

    function reset(setFormPristine) {
      $ctrl.topic = undefined
      $ctrl.report = {
        type: undefined,
        caller: undefined,
        note: undefined,
      }
      delete $ctrl.customerInquiry
      $ctrl.today = new Date()

      if (setFormPristine && $ctrl.reportingForm) {
        $ctrl.reportingForm.$setPristine()
        $ctrl.reportingForm.$setUntouched()
      }
    }

    function selectCustomer(customer) {
      $ctrl.customerId = customer ? customer.customerId : undefined
      $ctrl.customer = customer
    }

    function selectStore(store) {
      $ctrl.storeId = store ? store.storeId : undefined
      $ctrl.store = store
      $ctrl.storeContacts = undefined

      if (storesAll && store) {
        ServicecenterService.getContacts($ctrl.customerId, store.storeId).then((response) => {
          $ctrl.storeContacts = response.length ? response[0].contacts : undefined
        })
      }
    }

    function selectTopic(topic) {
      $ctrl.report.type = topic.value
    }

    function editReportFromRoot() {
      if (!$scope.$root.reportingEditReport) {
        return
      }

      var report = $scope.$root.reportingEditReport
      delete $scope.$root.reportingEditReport
      reportEdit(report)
      if (!report.store) {
        loadLastReports()
        loadPinboard()
      }
    }
  }
})()
