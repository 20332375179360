(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.statistics', ['omPushNotification'])
    .config(function ($stateProvider, moduleRegisterProvider) {
      $stateProvider.state('statistics', {
        url: '/statistics',
        abstract: true,
        views: {
          '@': {
            templateUrl: 'src/statistics/views/layout.html'
          }
        }
      }).state('statistics.orders', {
        url: '/orders',
        data: {
          showStoreSelect: true,
          daysOffset: 1,
        },
        views: {
          'colMain@statistics': {
            templateUrl: 'src/statistics/views/orders.html',
            controller: 'StatisticsOrdersController',
          },
        },
        resolve: {
          googlePromise: function (googleChartApiPromise) { return googleChartApiPromise },
        },
      }).state('statistics.postcodes', {
        url: '/postcodes',
        data: {
          showStoreSelect: true,
          daysOffset: 0,
        },
        views: {
          'colMain@statistics': {
            templateUrl: 'src/statistics/views/postcodes.html',
            controller: 'StatisticsPostcodesController',
          },
        },
      }).state('statistics.newsletter', {
        url: '/newsletter',
        data: {
          showStoreSelect: true,
          customerFilter: function (customer) {
            return customer.customerSystemAssociations.filter(function (csa) {
              return csa.system.origin === 'ottomatik_magento'
            }).length > 0
          }
        },
        views: {
          'colMain@statistics': {
            templateUrl: 'src/statistics/views/newsletter.html',
            controller: 'StatisticsNewsletterController'
          }
        }
      }).state('statistics.openemm', {
        url: '/openemm',
        data: {
          customerFilter: function (customer) { return customer.options.hasOpenemm },
          months: {
            from: '2021-04',
          },
        },
        views: {
          'colMain@statistics': {
            templateUrl: 'src/statistics/views/openemm.html',
            controller: 'StatisticsOpenemmController',
          },
        },
      }).state('statistics.subacc', {
        url: '/subacc',
        data: {
          customerFilter: function (customer) {
            return customer.customerSystemAssociations.some(function (csa) {
              return csa.system.origin === 'ottomatik_magento'
            })
          },
          showStoreSelect: true,
        },
        views: {
          'colMain@statistics': {
            templateUrl: 'src/statistics/views/subacc.html',
            controller: 'StatisticsSubAccController',
            controllerAs: 'vm',
          },
        },
      }).state('statistics.bonuspoints', {
        url: '/bonuspoints',
        data: {
          customerFilter: function (customer) { return customer.options.hasBonuspoints },
          showStoreSelect: true
        },
        views: {
          'colMain@statistics': {
            templateUrl: 'src/statistics/views/bonuspoints.html',
            controller: 'StatisticsBonuspointsController'
          }
        }
      }).state('statistics.pushnotifications', {
        url: '/pushnotifications',
        data: {
          customerFilter: function (customer) {
            return customer.customerSystemAssociations.filter(function (csa) {
              return csa.system.origin === 'ottomatik_magento'
            }).length > 0
          },
          months: {
            from: '2019-01',
          },
        },
        views: {
          'colMain@statistics': {
            templateUrl: 'src/statistics/views/pushnotifications.html',
            controller: 'StatisticsPushNotificationsController'
          }
        }
      }).state('statistics.reporting', {
        url: '/reporting',
        data: {
          showStoreSelect: true,
          daysOffset: 0,
          months: {
            from: '2018-10',
          },
        },
        views: {
          'colMain@statistics': {
            templateUrl: 'src/statistics/views/reporting.html',
            controller: 'StatisticsReportingController',
            controllerAs: 'vm',
          },
        },
      }).state('statistics.articles', {
        url: '/articles',
        data: {
          showStoreSelect: true,
          daysOffset: 1,
          months: {
            from: '2020-01'
          },
        },
        views: {
          'colMain@statistics': {
            templateUrl: 'src/statistics/views/articles.html',
            controller: 'StatisticsArticlesController'
          }
        }
      }).state('statistics.deliverytime', {
        url: '/deliverytime',
        data: {
          showStoreSelect: true,
          daysOffset: 1,
          months: {
            from: '2021-01',
          },
        },
        views: {
          'colMain@statistics': {
            templateUrl: 'src/statistics/views/deliverytime.html',
            controller: 'StatisticsDeliverytimeController',
            controllerAs: 'vm',
          },
        },
      }).state('statistics.feedback', {
        url: '/feedback',
        data: {
          customerFilter: function (customer) { return customer.options.hasFeedback },
          showStoreSelect: true
        },
        views: {
          'colMain@statistics': {
            templateUrl: 'src/statistics/views/feedback.html',
            controller: 'StatisticsFeedbackController'
          }
        }
      }).state('statistics.locations', {
        url: '/locations',
        data: {
          customerFilter: function (customer) { return customer.options.hasGeocodingApiKey },
          daysOffset: 0,
          showStoreSelect: true,
        },
        views: {
          'colMain@statistics': {
            templateUrl: 'src/statistics/views/locations.html',
            controller: 'StatisticsLocationsController',
          },
        },
      }).state('statistics.trend', {
        url: '/trend',
        data: {
          showStoreSelect: true,
        },
        views: {
          'colMain@statistics': {
            templateUrl: 'src/statistics/views/trend.html',
            controller: 'StatisticsTrendController',
            controllerAs: 'trendCtrl',
          },
        },
        resolve: {
          googlePromise: function (googleChartApiPromise) { return googleChartApiPromise },
        },
      }).state('statistics.marketing', {
        url: '/marketing',
        data: {
          showStoreSelect: true,
          months: {
            from: '2025-01',
          },
        },
        views: {
          'colMain@statistics': {
            templateUrl: 'src/statistics/views/marketing.html',
            controller: 'StatisticMarketingController',
            controllerAs: 'marketingCtrl',
          },
        },
      })

      moduleRegisterProvider.add({
        id: 'statistics',
        title: 'Statistiken',
        defaultUrl: 'statistics.orders',
        materialIcon: 'insights',
        accessRoles: [
          'admin',
          'statistics_articles',
          'statistics_bonuspoints',
          'statistics_deliverytime',
          'statistics_feedback',
          'statistics_locations',
          'statistics_marketing',
          'statistics_newsletter',
          'statistics_newsletter_openemm',
          'statistics_orders',
          'statistics_postcodes',
          'statistics_pushnotifications',
          'statistics_reporting',
          'statistics_subacc',
          'statistics_trend',
        ],
        menu: [
          {
            name: 'Bestellungen',
            url: 'statistics.orders',
            accessRoles: ['admin', 'statistics_orders'],
          },
          {
            name: 'Trend',
            url: 'statistics.trend',
            accessRoles: ['admin', 'statistics_trend'],
          },
          {
            name: 'Postleitzahlen',
            url: 'statistics.postcodes',
            accessRoles: ['admin', 'statistics_postcodes'],
          },
          {
            name: 'Heatmap',
            url: 'statistics.locations',
            accessRoles: ['admin', 'statistics_locations'],
          },
          {
            name: 'Bearbeitungszeit',
            url: 'statistics.deliverytime',
            accessRoles: ['admin', 'statistics_deliverytime'],
          },
          {
            name: 'Artikel',
            url: 'statistics.articles',
            accessRoles: ['admin', 'statistics_articles'],
          },
          {
            name: 'Feedback',
            url: 'statistics.feedback',
            accessRoles: ['admin', 'statistics_feedback'],
          },
          {
            name: 'Newsletter',
            url: 'statistics.newsletter',
            accessRoles: ['admin', 'statistics_newsletter'],
            arn: {
              'Newsletter\u2002\u2022\u2002Alt': ['statistics_newsletter_openemm'],
              'Newsletter\u2002\u2022\u2002Magento': ['admin'],
            },
          },
          {
            name: 'Newsletter',
            url: 'statistics.openemm',
            accessRoles: ['admin', 'statistics_newsletter_openemm'],
            arn: {
              'Newsletter\u2002\u2022\u2002OpenEMM': ['admin'],
            },
          },
          {
            name: 'Push Benachrichtigungen',
            url: 'statistics.pushnotifications',
            accessRoles: ['admin', 'statistics_pushnotifications'],
          },
          {
            name: 'Abonnenten & Benutzer',
            url: 'statistics.subacc',
            accessRoles: ['admin', 'statistics_subacc'],
          },
          {
            name: 'VIP Punkte',
            url: 'statistics.bonuspoints',
            accessRoles: ['admin', 'statistics_bonuspoints'],
          },
          {
            name: 'Meldungen',
            url: 'statistics.reporting',
            accessRoles: ['admin', 'statistics_reporting'],
          },
          {
            name: 'Marketing (UTM Parameter)',
            url: 'statistics.marketing',
            accessRoles: ['admin', 'statistics_marketing'],
          },
        ]
      })
    })
    .factory('StatisticService', function (CONFIG, $resource, helperService) {
      var statUrl = CONFIG.API_URL + '/statistics/:customerId'
      var service = {}

      service.pushNotifications = $resource(CONFIG.API_URL + '/mercury/:customerId', { customerId: 0 })

      service.reporting = $resource(statUrl + '/reporting', { customerId: 0 })

      // Articles
      var Articles = $resource(statUrl + '/articles', { customerId: 0 })
      var ArticlesTypeExport = $resource(statUrl + '/articles/export/:type', { customerId: 0 })
      var ArticlesAutocomplete = $resource(statUrl + '/articles/autocomplete', { hideGlobalSpinner: true, customerId: 0 })
      var ArticlesMatch = $resource(statUrl + '/articles/match', { hideGlobalSpinner: true, customerId: 0 })
      service.getArticlesStatistic = function (params) {
        return Articles.query(params).$promise
      }
      service.exportArticlesStatistic = function (params) {
        if (params.type) {
          return ArticlesTypeExport.export(params).$promise
        }
        return Articles.export(params).$promise
      }
      service.getArticlesAutocomplete = function (params) {
        return ArticlesAutocomplete.query(params).$promise
      }
      service.matchArticle = function (params) {
        return ArticlesMatch.get(params).$promise
      }

      // Bonuspoints
      var Bonuspoints = $resource(CONFIG.API_URL + '/bonuspoints/:customerId', { customerId: 0 })
      var BonuspointsStore = $resource(CONFIG.API_URL + '/bonuspoints/:customerId/store/:storeId', { customerId: 0, storeId: 0 })
      service.getBonuspoints = function (params) {
        if (params.export) {
          return Bonuspoints.export(params).$promise
        }
        return Bonuspoints.get(params).$promise
      }
      service.getBonuspointsStore = function (params) {
        if (params.export) {
          return BonuspointsStore.export(params).$promise
        }
        return BonuspointsStore.get(params).$promise
      }

      // Feedback
      var Feedback = $resource(statUrl + '/feedback', { customerId: 0 })
      var FeedbackEntry = $resource(statUrl + '/feedback/entry/:id', { customerId: 0, id: 0 })
      service.getFeedbacks = function (params) {
        return Feedback.query(params).$promise
      }
      service.updateFeedbackEntry = function (params, data) {
        return FeedbackEntry.update(params, data).$promise
      }
      service.deleteFeedbackEntry = function (params) {
        return FeedbackEntry.delete(params).$promise
      }

      // Locations
      var Locations = $resource(statUrl + '/address-locations', { customerId: 0 })
      service.getLocations = function (params) {
        return Locations.get(params).$promise
      }

      // Magento Newsletters
      var Newsletter = $resource(statUrl + '/newsletter', { customerId: 0 })
      service.getNewsletters = function (params) {
        var paginationCount = 0
        return Newsletter.query(params, function (response, headers) {
          paginationCount = parseInt(headers('pagination-count') || 0)
        }).$promise.then(function (response) {
          return {
            data: response,
            paginationCount: paginationCount,
          }
        })
      }
      service.getNewslettersExport = function (params) {
        return Newsletter.export(params).$promise
      }
      service.getNewsletterPreviewUrl = function (customerId, newsletterId) {
        return CONFIG.API_URL + '/customers/' + customerId + '/newsletter/' + newsletterId + '/preview'
      }

      // OpenEMM
      var OpenEmm = $resource(statUrl + '/newsletter/openemm', { customerId: 0 })
      var OpenEmmMailing = $resource(CONFIG.API_URL + '/customers/:customerId/newsletter/:mailingId/openemm')
      service.getOpenEmmStatistics = function (params) {
        var paginationCount = 0
        return OpenEmm.query(params, function (response, headers) {
          paginationCount = parseInt(headers('pagination-count') || 0)
        }).$promise
          .then(function (response) {
            return {
              data: response,
              paginationCount: paginationCount,
            }
          })
      }
      service.getOpenEmmMailing = function (params) {
        return OpenEmmMailing.get(params).$promise
      }

      // Orders
      var Orders = $resource(statUrl + '/orders', { customerId: 0 })
      var ClusterStores = $resource(statUrl + '/cluster-stores', { customerId: 0 })
      service.getOrders = function (params) {
        return Orders.get(params).$promise
      }
      service.exportOrders = function (params) {
        return Orders.export(params).$promise
      }
      service.groupStoresByMonthlySales = function (params) {
        return ClusterStores.query(params).$promise
      }

      // Postcodes
      var Postcodes = $resource(statUrl + '/postcodes', { customerId: 0 })
      service.getPostcodes = function (params) {
        return Postcodes.get(params).$promise
      }
      service.exportPostcodes = function (params) {
        return Postcodes.export(params).$promise
      }

      // Deliverytime
      var Deliverytime = $resource(statUrl + '/deliverytime', { customerId: 0 })
      service.getDeliverytimes = function (params) {
        return Deliverytime.query(params).$promise
      }

      // Accounts & Subscribers
      var SubAcc = $resource(statUrl + '/subacc', { customerId: 0 })
      service.getAccountsSubscribers = function (params) {
        return SubAcc.query(params).$promise
      }
      service.exportAccountsSubscribers = function (params) {
        return SubAcc.export(params).$promise
      }

      // Trend
      var Trend = $resource(statUrl + '/trend', { customerId: 0 })
      service.getTrend = function (params) {
        return Trend.query(params).$promise
      }

      return service
    })
    .factory('StatisticFilterService', function (helperService) {
      var self = this
      self.daysOffset = undefined
      self.dateFrom = undefined
      self.dateTo = undefined
      self.months = []
      self.monthSelect = undefined

      var service = {}

      service.setDaysOffset = function (daysOffset) {
        self.daysOffset = daysOffset
      }

      service.setMonthsFilter = function (filter) {
        var months = helperService.getSelectMonthOptions(filter)
        var max = service.getMaxDate()
        if (max) {
          var i = months.length
          while (i--) {
            if (max < months[i].first) {
              months.splice(i, 1)
            }
          }
        }
        self.months = months
        self.monthSelect = moment().format('MMMM YYYY')
      }

      service.getMonths = function () {
        return self.months
      }

      service.getMonth = function () {
        var months = service.getMonths()
        if (!months.length) {
          return undefined
        }
        var i = 0
        if (self.monthSelect) {
          i = months.findIndex(
            function (month) {
              return month.name === self.monthSelect
            }
          )
          if (i < 0) {
            i = 0
          }
        }
        var month = months[i]
        service.setMonth(month)
        return month
      }

      service.setMonth = function (month) {
        self.monthSelect = undefined
        self.dateFrom = undefined
        self.dateTo = undefined
        if (!month) {
          return
        }
        self.monthSelect = month.name
        self.dateFrom = new Date(month.first)
        self.dateTo = new Date(month.last)
        var max = service.getMaxDate()
        if (max && self.dateTo > max) {
          self.dateTo = max
        }
      }

      service.getDateFrom = function () {
        return self.dateFrom
      }

      service.getDateTo = function () {
        return self.dateTo
      }

      service.getMaxDate = function () {
        if (self.daysOffset == null) {
          return undefined
        }
        var max = new Date()
        max.setHours(0, 0, 0, 0)
        max.setDate(max.getDate() - self.daysOffset)
        return max
      }

      return service
    })
    .factory('ChartService', function () {
      var lastDefaultIndex = 0
      var defaults = [
        { color: '#3366CC', lighter: '#45AFE2' },
        { color: '#DC3912', lighter: '#FF3300' },
        { color: '#FF9900', lighter: '#FFCC00' },
        { color: '#109618', lighter: '#14C21D' },
        { color: '#990099', lighter: '#DF51FD' },
        { color: '#0099C6', lighter: '#15CBFF' },
        { color: '#DD4477', lighter: '#FF97D2' },
        { color: '#66AA00', lighter: '#97FB00' },
        { color: '#B82E2E', lighter: '#DB6651' },
        { color: '#316395', lighter: '#518BC6' },
        { color: '#994499', lighter: '#BD6CBD' },
        { color: '#22AA99', lighter: '#35D7C2' },
        { color: '#AAAA11', lighter: '#E9E91F' },
        { color: '#6633CC', lighter: '#9877DD' },
        { color: '#E67300', lighter: '#FF8F20' },
        { color: '#8B0707', lighter: '#D20B0B' },
        { color: '#651067', lighter: '#B61DBA' },
        { color: '#329262', lighter: '#40BD7E' },
        { color: '#5574A6', lighter: '#6AA7C4' },
        { color: '#3B3EAC', lighter: '#6D70CD' },
        { color: '#B77322', lighter: '#DA9136' },
        { color: '#16D620', lighter: '#2DEA36' },
        { color: '#B91383', lighter: '#E81EA6' },
        { color: '#F4359E', lighter: '#F558AE' },
        { color: '#9C5935', lighter: '#C07145' },
        { color: '#A9C413', lighter: '#D7EE53' },
        { color: '#2A778D', lighter: '#3EA7C6' },
        { color: '#668D1C', lighter: '#97D129' },
        { color: '#BEA413', lighter: '#E9CA1D' },
        { color: '#0C5922', lighter: '#149638' },
        { color: '#743411', lighter: '#C5571D' },
      ]

      var service = {
        getColor: getColor,
        resetColor: resetColor,
      }
      return service

      function getColor (customer, lighter) {
        // lighter: [sass] color.scale($color, $lightness: 50%);
        var color
        switch (customer) {
          case 'Tele Pizza':
            color = '#c50006'
            if (lighter) {
              color = '#e28083'
            }
            break
          case 'Call a Pizza':
            color = '#cb0e21'
            if (lighter) {
              color = '#e58790'
            }
            break
          case 'Flying Pizza':
            color = '#80b82a'
            if (lighter) {
              color = '#c0dc95'
            }
            break
          case 'pizza.de':
            color = '#ffc500'
            if (lighter) {
              color = '#ffe280'
            }
            break
          case 'Lieferheld':
            color = '#d61f26'
            if (lighter) {
              color = '#eb8f93'
            }
            break
          case 'Lieferando':
            color = '#ff8000'
            if (lighter) {
              color = '#ffc080'
            }
            break
          case 'Clickfood':
            color = '#64b32c'
            if (lighter) {
              color = '#b2d996'
            }
            break
          case 'SIDES':
            color = '#5a0024'
            if (lighter) {
              color = '#ad8092'
            }
            break
          case 'Wolt':
            color = '#00c2e8'
            if (lighter) {
              color = '#80e1f4'
            }
            break
          case 'Uber':
            color = '#06c167'
            if (lighter) {
              color = '#68fab4'
            }
          default:
            var palette = defaults[lastDefaultIndex++]
            color = lighter ? palette.lighter : palette.color
            if (lastDefaultIndex === defaults.length) {
              lastDefaultIndex = 0
            }
            break
        }
        return color
      }

      function resetColor () {
        lastDefaultIndex = 0
        return service
      }
    })
    .controller('StatisticsFilterController', function ($scope, $state, $transitions, StatisticFilterService, UserService, CustomerService) {
      var filter = this
      var stateData = $state.current.data || {}

      StatisticFilterService.setDaysOffset(stateData.daysOffset)
      StatisticFilterService.setMonthsFilter(stateData.months)

      filter.initCustomerSelect = function () {
        CustomerService.getCustomers().$promise.then(
          function (customers) {
            filter.customers = stateData.customerFilter ? customers.filter(stateData.customerFilter) : customers
          }
        )
        if (!filter.customerSelect) {
          filter.customerSelect = function (customerId, customer) {
            filter.customerId = customerId
            filter.customer = customer
            filter.dates._update(customer)
            filter.initStoreSelect()
          }
        }
      }

      filter.initStoreSelect = function () {
        if (!$state.current.data || !$state.current.data.showStoreSelect) {
          return
        }
        if (!filter.customerId) {
          return
        }
        CustomerService.getStoresWithGroups(filter.customerId).$promise.then(
          function (response) {
            if (!filter.customerId) {
              filter.stores = []
              filter.storegroups = []
              return
            }
            filter.stores = response.stores
            filter.storegroups = response.groups
            if (filter.stores.length === 1) {
              var store = filter.stores[0]
              filter.storeSelect(store.storeId, store)
            }
          }
        )
        if (!filter.storeSelect) {
          filter.storeSelect = function (storeId, store) {
            filter.storeId = storeId
            filter.store = store
          }
        }
      }

      filter.month = {
        select: StatisticFilterService.getMonth(),
        options: StatisticFilterService.getMonths(),
        change: function () {
          StatisticFilterService.setMonth(this.select)
          filter.dates.from = StatisticFilterService.getDateFrom()
          filter.dates.to = StatisticFilterService.getDateTo()
        }
      }

      filter.dates = {
        from: StatisticFilterService.getDateFrom(),
        to: StatisticFilterService.getDateTo(),
        change: function (field, resetMonth) {
          if (undefined === resetMonth) {
            resetMonth = true
          }
          if (!(this.to && this.from) || this.to < this.from) {
            if (field === 'from') {
              this.to = this.from
            } else {
              this.from = this.to
            }
          }
          if (resetMonth && filter.month.select && (this.from < filter.month.select.first || filter.month.select.last < this.to)) {
            filter.month.select = undefined
          }
        },
        _min: undefined,
        _max: StatisticFilterService.getMaxDate(),
        _update: function (customer) {
          if (customer) {
            this._min = new Date(customer.onlineSince)
          } else {
            this._min = undefined
          }
        }
      }

      filter.timeRange = {
        value: '',
        change: function (event) {
          this.value = this.value.replace(/[^\d: -]/g, '')
          var range = this.value.match(/^(\d{0,2}):?(\d{0,2})[ -]*(\d{0,2}):?(\d{0,2})$/)
          if (!this.value.length || range === null) {
            this.value = ''
            return
          }

          var h1 = range[1]
          var m1 = range[2]
          var h2 = range[3]
          var m2 = range[4]

          var fill = event && (event.which === 13 || event.type === 'blur')
          if (fill) {
            h1 = Math.min(h1, 23)
            m1 = Math.min(m1, 59)
            h2 = Math.min(h2, 23)
            m2 = Math.min(m2, 59)
            h1 = '0'.repeat(2 - h1.toString().length) + h1
            m1 = '0'.repeat(2 - m1.toString().length) + m1
            h2 = '0'.repeat(2 - h2.toString().length) + h2
            m2 = '0'.repeat(2 - m2.toString().length) + m2
            this.value = h1 + ':' + m1 + ' - ' + h2 + ':' + m2
            event.target.blur()
          }
        }
      }

      filter.limit = undefined
      filter.page = undefined
      filter.updatePagination = function (pagination, callback) {
        var init = filter.limit == null
        if (pagination.limit != null) {
          filter.limit = pagination.limit
        }
        if (pagination.page != null) {
          filter.page = pagination.page
        }
        if (!init && angular.isFunction(callback)) {
          callback()
        }
      }

      filter.showCustomerSelect = UserService.hasRole('admin') || UserService.hasRole('storesall')
      if (filter.showCustomerSelect) {
        filter.initCustomerSelect()
      } else {
        filter.customerId = UserService.getCustomerId()
        CustomerService.getCustomer(filter.customerId).$promise.then(function (customer) {
          filter.customer = customer
          filter.dates._update(customer)
          filter.initStoreSelect()
        })
      }

      var deregisterTransitionHook = $transitions.onSuccess({}, function (transition) {
        if (transition.noStatFilterReset) {
          return
        }
        stateData = transition.to().data || {}
        // update state dependent variables
        StatisticFilterService.setDaysOffset(stateData.daysOffset)
        StatisticFilterService.setMonthsFilter(stateData.months)
        filter.month.select = StatisticFilterService.getMonth()
        filter.month.options = StatisticFilterService.getMonths()
        filter.dates.from = StatisticFilterService.getDateFrom()
        filter.dates.to = StatisticFilterService.getDateTo()
      })

      var deregisterWatcher = $scope.$watch(
        'filter',
        function (newValue, oldValue) {
          if (newValue.limit === oldValue.limit && newValue.page === oldValue.page) {
            filter.page = 1
          }
        },
        true
      )

      $scope.$on('$destroy', function () {
        deregisterTransitionHook()
        deregisterWatcher()
      })

      // make filter accessible to parent
      $scope.$parent.filter = filter
    })
    .controller('StatisticsOrdersController', function ($filter, $mdDateLocale, $scope, ChartService, RankingService, StatisticService, UserService) {
      var self = this
      $scope.data = undefined

      $scope.showOnlyCharts = UserService.hasRole('stats-orders-only-charts')

      $scope.view = {
        current: 'overview',
        options: {
          overview: {
            icon: 'visibility',
            title: 'Übersicht',
            charts: true,
          },
          list: {
            icon: 'store',
            title: 'pro Store anzeigen',
            charts: false,
          },
        },
        change: function (id) {
          if (this.current !== id) {
            $scope.data = undefined
            this.current = id
          }
        },
      }

      $scope.load = function () {
        if ($scope.loading) {
          return
        }
        $scope.loading = true

        var params = {
          customerId: $scope.$parent.filter.customerId,
          storeId: $scope.$parent.filter.storeId,
          systemId: $scope.systemId,
          dateFrom: $filter('date')($scope.$parent.filter.dates.from),
          dateTo: $filter('date')($scope.$parent.filter.dates.to),
          type: $scope.view.current,
        }

        StatisticService.getOrders(params)
          .then(function (response) {
            $scope.data = response
            self.exportParams = angular.copy(params)
            if ($scope.view.options[params.type].charts) {
              $scope.charts.draw()
            }
          })
          .finally(function () {
            $scope.loading = false
          })
      }

      self.exportParams = undefined
      $scope.export = function (rpc) {
        if ($scope.loading || self.exportParams == null) {
          return
        }
        $scope.loading = true

        var params = angular.copy(self.exportParams)
        if (rpc) {
          params.rpc = rpc
        }

        StatisticService.exportOrders(params)
          .finally(function () {
            $scope.loading = false
          })
      }

      $scope.charts = {
        getPieChartOptions: function (title) {
          return {
            backgroundColor: 'transparent',
            fontName: 'Roboto',
            fontSize: 14,
            height: 400,
            is3D: true,
            legend: {
              alignment: 'center',
              pagingTextStyle: {
                color: '#0078d7',
              },
              scrollArrows: {
                activeColor: '#0078d7',
              },
            },
            sliceVisibilityThreshold: 0,
            title: title,
            titleTextStyle: {
              fontSize: 16,
            },
            tooltip: {
              showColorCode: true,
            },
          }
        },
        getComboChartOptions: function (title) {
          return {
            animation: {
              duration: 250,
              easing: 'out',
              startup: true,
            },
            backgroundColor: 'transparent',
            focusTarget: 'category',
            fontName: 'Roboto',
            fontSize: 14,
            height: 400,
            isStacked: true,
            legend: {
              position: 'top',
            },
            series: [],
            title: title,
            titleTextStyle: {
              fontSize: 16,
            },
            hAxis: {
              gridlines: {},
              minorGridlines: {
                count: 0,
              },
              textPosition: 'none',
            },
            vAxes: [{
              viewWindow: { min: 0 },
            }],
          }
        },
        draw: function () {
          if (!($scope.data && $scope.data.statistics && $scope.data.statistics.length)) {
            delete this.system
            delete this.shipping
            delete this.device
            delete this.payment
            delete this.gender
            delete this.daily
            delete this.salesByWeekday
            return
          }

          var systemChart = {
            type: 'PieChart',
            data: new google.visualization.DataTable(),
            options: this.getPieChartOptions('Bestellungen je Herkunft'),
          }
          systemChart.data.addColumn('string', 'Herkunft')
          systemChart.data.addColumn('number', 'Bestellungen')

          var shippingChart = {
            type: 'PieChart',
            data: new google.visualization.DataTable(),
            options: this.getPieChartOptions('Bestellungen je Lieferart'),
          }
          shippingChart.data.addColumn('string', 'Lieferart')
          shippingChart.data.addColumn('number', 'Bestellungen')

          var deviceChart = {
            type: 'PieChart',
            data: new google.visualization.DataTable(),
            options: this.getPieChartOptions('Bestellungen je Plattform'),
          }
          deviceChart.data.addColumn('string', 'Plattform')
          deviceChart.data.addColumn('number', 'Bestellungen')

          var paymentChart = {
            type: 'PieChart',
            data: new google.visualization.DataTable(),
            options: this.getPieChartOptions('Bestellungen je Zahlungsart'),
          }
          paymentChart.data.addColumn('string', 'Zahlungsart')
          paymentChart.data.addColumn('number', 'Bestellungen')

          var genderChart = {
            type: 'PieChart',
            data: new google.visualization.DataTable(),
            options: this.getPieChartOptions('Bestellungen je Geschlecht'),
          }
          genderChart.data.addColumn('string', 'Geschlecht')
          genderChart.data.addColumn('number', 'Bestellungen')

          var dailyChart = {
            type: 'ComboChart',
            data: new google.visualization.DataTable(),
            options: this.getComboChartOptions('Bestellungen pro Tag'),
          }
          dailyChart.data.addColumn('date', 'Tag')

          var dataSystem = []
          var dataShipping = []
          var dataDevice = []
          var dataPayment = []
          var dataGender = []
          var dataDaily = []

          var dateFirst = new Date($scope.data.dateFirst)
          var dateLast = new Date($scope.data.dateLast)
          var dateDiff = $filter('dateDiff')([dateFirst, dateLast])
          var hAxisGridlinesCount = 0
          for (var d = 0, dateThis, dateRun = new Date(dateFirst); d <= dateDiff; d++, dateRun.setDate(dateRun.getDate() + 1)) {
            var row = new Array($scope.data.statistics.length + 1).fill(0)
            dateThis = new Date(dateRun)
            dateThis.setHours(12)
            row[0] = { v: dateThis, f: $filter('date')(dateThis, 'EEEE, d. MMMM yyyy') }
            dataDaily.push(row)
            if (moment(dateThis).weekday() === 0) {
              hAxisGridlinesCount++
            }
          }
          dailyChart.options.hAxis.gridlines.count = dateDiff < 3 ? 0 : hAxisGridlinesCount

          var len = $scope.data.statistics.length
          for (var i = 0, r = [0, 0], j; i < len; i++) {
            row = $scope.data.statistics[i]

            dataSystem.push([row.system, row.ordersTotal])

            j = -1
            dataShipping[++j] = ['Lieferung', (dataShipping[j] || r)[1] + row.ordersShippingDelivery]
            dataShipping[++j] = ['Selbstabholer', (dataShipping[j] || r)[1] + row.ordersShippingSelfCollector]
            dataShipping[++j] = ['Restaurant', (dataShipping[j] || r)[1] + row.ordersShippingRestaurant]
            dataShipping[++j] = ['Personal', (dataShipping[j] || r)[1] + row.ordersShippingStaff]

            j = -1
            dataDevice[++j] = ['Website (Desktop)', (dataDevice[j] || r)[1] + row.ordersWebsiteDesktop]
            dataDevice[++j] = ['Website (Tablet)', (dataDevice[j] || r)[1] + row.ordersWebsiteTablet]
            dataDevice[++j] = ['Website (Phone)', (dataDevice[j] || r)[1] + row.ordersWebsitePhone]
            dataDevice[++j] = ['App (iOS)', (dataDevice[j] || r)[1] + row.ordersAppIos]
            dataDevice[++j] = ['App (Android)', (dataDevice[j] || r)[1] + row.ordersAppAndroid]
            dataDevice[++j] = ['App (Windows)', (dataDevice[j] || r)[1] + row.ordersAppWindows]
            dataDevice[++j] = ['Amazon Alexa', (dataDevice[j] || r)[1] + row.ordersAssistantAlexa]
            dataDevice[++j] = ['Google Assistant', (dataDevice[j] || r)[1] + row.ordersAssistantGoogle]

            j = -1
            var summedOnline = (row.ordersPaymentPaypal + row.ordersPaymentGiropay + row.ordersPaymentInstanttransfer + row.ordersPaymentAmazon) > 0
            dataPayment[++j] = ['Barzahlung', (dataPayment[j] || r)[1] + row.ordersPaymentCash]
            dataPayment[++j] = ['EC-Karte', (dataPayment[j] || r)[1] + row.ordersPaymentEc]
            dataPayment[++j] = ['Kreditkarte', (dataPayment[j] || r)[1] + row.ordersPaymentCreditcard]
            dataPayment[++j] = ['Apply Pay', (dataPayment[j] || r)[1] + row.ordersPaymentApple]
            dataPayment[++j] = ['Google Pay', (dataPayment[j] || r)[1] + row.ordersPaymentGoogle]
            dataPayment[++j] = ['Online-Bezahldienst (unbekannt)', (dataPayment[j] || r)[1] + (summedOnline ? 0 : row.ordersPaymentOnline)]
            dataPayment[++j] = ['PayPal', (dataPayment[j] || r)[1] + row.ordersPaymentPaypal]
            dataPayment[++j] = ['giropay', (dataPayment[j] || r)[1] + row.ordersPaymentGiropay]
            dataPayment[++j] = ['Klarna Sofort', (dataPayment[j] || r)[1] + row.ordersPaymentInstanttransfer]
            dataPayment[++j] = ['Amazon Pay', (dataPayment[j] || r)[1] + row.ordersPaymentAmazon]

            j = -1
            dataGender[++j] = ['Männlich', (dataGender[j] || r)[1] + row.prefixMale]
            dataGender[++j] = ['Weiblich', (dataGender[j] || r)[1] + row.prefixFemale]
            dataGender[++j] = ['Keine Angabe', (dataGender[j] || r)[1] + row.prefixNone]

            dailyChart.data.addColumn('number', row.system)
            dailyChart.options.series.push({ type: 'bars' })
            row.ordersDaily.forEach(function (dateAndCount) {
              var match = String(dateAndCount[0]).match(/(\d\d)(\d\d)(\d\d)/)
              var date = new Date(2000 + parseInt(match[1]), match[2] - 1, match[3])
              var count = dateAndCount[1]
              var index = $filter('dateDiff')([dateFirst, date])
              dataDaily[index][i + 1] += count
            })
          }

          var colors = this.getColors(dataSystem)
          systemChart.options.colors = angular.copy(colors)
          dailyChart.options.colors = angular.copy(colors)

          if (len > 1) {
            dailyChart.data.insertColumn(1, 'number', 'Gesamt')
            dailyChart.options.colors.unshift('transparent')
            dailyChart.options.vAxes.push({
              textPosition: 'none',
              gridlines: { count: 0 },
              minorGridlines: { count: 0 },
              viewWindow: { min: -2, max: -1 },
            })
            dailyChart.options.series.unshift({
              targetAxisIndex: dailyChart.options.vAxes.length - 1,
              visibleInLegend: false,
            })
            dataDaily.forEach(function (row) {
              var sum = row.filter(function (value, index) { return index }).reduce(function (sum, value) { return sum + value }, 0)
              row.splice(1, 0, sum)
            })
          }

          systemChart.data.addRows(this.removeZeroValues(dataSystem))
          shippingChart.data.addRows(this.removeZeroValues(dataShipping))
          deviceChart.data.addRows(this.removeZeroValues(dataDevice))
          paymentChart.data.addRows(this.removeZeroValues(dataPayment))
          genderChart.data.addRows(this.removeZeroValues(dataGender))
          dailyChart.data.addRows(dataDaily)

          if (!shippingChart.data.getNumberOfRows()) {
            shippingChart = null
          }
          if (!deviceChart.data.getNumberOfRows()) {
            deviceChart = null
          }
          if (!genderChart.data.getNumberOfRows()) {
            genderChart = null
          }

          this.system = systemChart
          this.shipping = shippingChart
          this.device = deviceChart
          this.payment = paymentChart
          this.gender = genderChart
          this.daily = dailyChart

          if ($scope.data.hours && $scope.data.hours.length) {
            var salesByWeekday = $scope.data.hours.reduce(function (collection, _) {
              // _ = {
              //   system: {...},
              //   weeks: {
              //     <weekday>: <int>,
              //     ...
              //   },
              //   hours: {
              //     <weekday>: [
              //       <hour>: { count: <int>, total: <float> },
              //       ...
              //     ],
              //     ...
              //   },
              // }

              var totalSystemWeekdays = Object.values(_.weeks).reduce(function (a, b) {
                return a + b
              }, 0)

              var allSystemWeekdays = {
                system: _.system,
                hours: [],
              }

              Object.keys(_.hours).forEach(function (weekday) {
                if (!collection[weekday]) {
                  collection[weekday] = []
                }
                collection[weekday].push({
                  system: _.system,
                  hours: _.hours[weekday].map(function (hourData, hour) {

                    if (!allSystemWeekdays.hours[hour]) {
                      allSystemWeekdays.hours[hour] = {
                        count: 0,
                        total: 0,
                      }
                    }

                    allSystemWeekdays.hours[hour].count += hourData.count / totalSystemWeekdays
                    allSystemWeekdays.hours[hour].total += hourData.total / totalSystemWeekdays

                    return {
                      count: hourData.count / _.weeks[weekday],
                      total: hourData.total / _.weeks[weekday],
                    }
                  }),
                })
              })

              collection.null.push(allSystemWeekdays)

              return collection
            }, { null: [] })

            $scope.salesWeekday = null
            $scope.salesWeekdays = angular.copy($mdDateLocale.days)
              .rotate($mdDateLocale.firstDayOfWeek)
              .map(function (day, index) {
                return {
                  id: index,
                  name: day,
                }
              })
              .filter(function (day) {
                return Object.keys(salesByWeekday).includes(String(day.id))
              })

            this.salesByWeekday = Object.keys(salesByWeekday).reduce(function (charts, key) {
              charts[key] = RankingService.getSalesChart(
                { systems: salesByWeekday[key] },
                $scope.filter.customer,
                {
                  startHour: 9,
                  endHour: 3,
                  title: 'Umsatz [Linien] und Bestellungen [Säulen] pro Stunde (Durchschnitt)',
                }
              )
              Object.assign(charts[key].options, {
                animation: {
                  duration: 500,
                  easing: 'out',
                },
                height: 400,
              })
              return charts
            }, {})
          } else {
            delete this.salesByWeekday
          }
        },
        getColors: function (data) {
          var colors = []
          for (var i = 0, len = data.length; i < len; i++) {
            colors.push(ChartService.getColor(data[i][0]))
          }
          return colors
        },
        removeZeroValues: function (data) {
          // reverse loop (don't skip deleted indices)
          for (var i = data.length - 1; i >= 0; i--) {
            if (isNaN(data[i][1]) || data[i][1] === 0) {
              // array.splice(start, deleteCount)
              data.splice(i, 1)
            }
          }
          return data
        },
      }
    })
    .controller('StatisticsPostcodesController', function ($filter, $scope, helperService, StatisticService, UserService) {
      var self = this
      $scope.data = undefined

      $scope.showCustomerCount = UserService.hasRole('stats-postcode-customer-count')

      $scope.load = function () {
        if ($scope.loading) {
          return
        }
        $scope.loading = true

        var params = {
          customerId: $scope.$parent.filter.customerId,
          storeId: $scope.$parent.filter.storeId,
          dateFrom: $filter('date')($scope.$parent.filter.dates.from),
          dateTo: $filter('date')($scope.$parent.filter.dates.to),
        }

        var storeSelected = angular.copy($scope.$parent.filter.store)

        StatisticService.getPostcodes(params)
          .then(function (response) {
            $scope.data = response
            $scope.storeSelected = storeSelected
            self.exportParams = angular.copy(params)
            $scope.sort._orderBy($scope.data.postcodes)
          })
          .finally(function () {
            $scope.loading = false
          })
      }

      self.exportParams = undefined
      $scope.export = function () {
        if ($scope.loading || self.exportParams == null) {
          return
        }
        $scope.loading = true

        StatisticService.exportPostcodes(self.exportParams)
          .finally(function () {
            $scope.loading = false
          })
      }

      $scope.sort = {
        field: 'postcode',
        reverse: false,
        order: function (newField, reverse) {
          if (this.field === newField) {
            this.reverse = !this.reverse
          } else {
            this.field = newField
            this.reverse = reverse != null ? reverse : true
          }
          this._orderBy($scope.data.postcodes)
        },
        _orderBy: function (postcodes) {
          $scope.postcodes = $filter('orderBy')(angular.copy(postcodes), [this._orderByExpression, (this.reverse ? '-' : '') + 'postcode'], this.reverse)
        },
        _orderByExpression: function (item) {
          return helperService.fetchFromObject(item, $scope.sort.field)
        },
      }
    })
    .controller('StatisticsNewsletterController', function (CONFIG, $filter, $scope, IframeService, StatisticService) {
      $scope.export = function (event) {
        $scope.load(event, true)
      }

      $scope.loading = false
      $scope.load = function (event, isExport) {
        if ($scope.loading) {
          return
        }

        var params = {
          customerId: $scope.filter.customerId,
          storeId: $scope.filter.storeId,
          dateFrom: $filter('date')($scope.filter.dates.from),
          dateTo: $filter('date')($scope.filter.dates.to),
          limit: $scope.filter.limit,
          page: $scope.filter.page,
        }

        $scope.loading = true
        var promise
        if (isExport) {
          promise = StatisticService.getNewslettersExport(params)
        } else {
          promise = StatisticService.getNewsletters(params)
          promise.then(function (response) {
            $scope.newsletters = response.data.map(function (newsletter) {
              var start = moment(newsletter.start)
              newsletter.isActive = start.isValid() && start.isBefore() && newsletter.finish === null
              return newsletter
            })
            $scope.paginationCount = response.paginationCount
          })
        }
        promise.finally(function () {
          $scope.loading = false
        })
      }

      $scope.showPreview = function (event, customerId, newsletter) {
        IframeService.open({
          event: event,
          title: newsletter.subject + '&ensp;&bull;&ensp;' + $filter('date')(newsletter.start),
          url: StatisticService.getNewsletterPreviewUrl(customerId, newsletter.id),
        })
      }
    })
    .controller('StatisticsBonuspointsController', function ($scope, $filter, StatisticService) {
      var self = this

      $scope.view = {
        current: 'overall',
        options: {
          overall: {
            icon: 'visibility',
            title: 'Übersicht',
          },
          store: {
            icon: 'store',
            title: 'pro Store anzeigen',
          },
        },
        change: function (view) {
          if (this.current !== view) {
            delete $scope.data
            this.current = view
          }
        },
      }

      $scope.export = function () {
        $scope.load(true, self.exportParams)
      }

      $scope.load = function (isExport, params) {
        if ($scope.loading) {
          return
        }
        $scope.loading = true

        if (!params) {
          params = {
            customerId: $scope.filter.customerId,
            dateFrom: $filter('date')($scope.filter.dates.from),
            dateTo: $filter('date')($scope.filter.dates.to),
          }
          if ($scope.view.current === 'store') {
            Object.assign(params, {
              storeId: $scope.filter.storeId,
              limit: $scope.filter.limit,
              page: $scope.filter.page,
            })
          }
        }

        var promise
        if ($scope.view.current === 'store') {
          promise = StatisticService.getBonuspointsStore(params)
        } else {
          promise = StatisticService.getBonuspoints(params)
        }
        promise
          .then(function (response) {
            if (!isExport) {
              self.exportParams = angular.copy(params)
              self.exportParams.export = true
              $scope.data = response
            }
          })
          .finally(function () {
            $scope.loading = false
          })
      }

      $scope.showDetails = function (store) {
        $scope.filter.storeSelect(store.storeId, store)
        $scope.view.change('store')
        $scope.load()
      }
    })
    .controller('StatisticsPushNotificationsController', function ($filter, $scope, StatisticService, UserService) {
      $scope.showFullResult = UserService.hasRole('admin')

      $scope.loading = false
      $scope.load = function () {
        if ($scope.loading) {
          return
        }

        var params = {
          customerId: $scope.filter.customerId,
          dateFrom: $scope.filter.dates.from ? $filter('date')($scope.filter.dates.from) : undefined,
          dateTo: $scope.filter.dates.to ? $filter('date')($scope.filter.dates.to) : undefined,
          search: $scope.search || undefined,
          limit: $scope.limit,
          page: $scope.page,
        }

        $scope.loading = true
        StatisticService.pushNotifications.get(params).$promise
          .then(function (response) {
            $scope.data = response
          })
          .finally(function () {
            $scope.loading = false
          })
      }

      $scope.updatePagination = function (pagination) {
        var init = $scope.limit === undefined
        $scope.limit = pagination.limit
        $scope.page = pagination.page
        if (!init) {
          $scope.load()
        }
      }
    })
})()
