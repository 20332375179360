;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.statistics')
    .controller('StatisticsFeedbackController', StatisticsFeedbackController)

  function StatisticsFeedbackController(
    $filter,
    $mdDialog,
    $scope,
    Notification,
    StatisticService,
    UNPKG,
    UserService
  ) {
    $scope.showMarkAsDone = UserService.hasRole(['admin', 'servicecenter', 'stats-feedback-markdone'])
    $scope.showUserContact = UserService.hasRole(['admin', 'servicecenter', 'stats-feedback-contact'])
    $scope.showDeleteButton = UserService.hasRole('admin')

    $scope.load = function (event) {
      if ($scope.loading) {
        return
      }

      var params = {
        customerId: $scope.filter.customerId,
        storeId: $scope.filter.storeId,
        dateFrom: $filter('date')($scope.filter.dates.from),
        dateTo: $filter('date')($scope.filter.dates.to),
      }

      $scope.loading = true
      StatisticService.getFeedbacks(params)
        .then(function (response) {
          $scope.feedbacks = $filter('orderBy')(response, ['store.foreignIdent', 'store.name'])
        })
        .finally(function () {
          $scope.loading = false
          $scope.showComplete = Boolean($scope.filter.customer.options.feedbackAlwaysComplete)
        })
    }

    $scope.openDialog = function (event, feedback) {
      return $mdDialog.show({
        templateUrl: 'src/statistics/views/feedback-dialog.html',
        targetEvent: event,
        clickOutsideToClose: true,
        focusOnOpen: false,
        controller: function () {},
        locals: {
          // feedback
          attributes: feedback.attributes,
          categories: feedback.categories.map(function (category) {
            var valueNames = {}
            category.values.forEach(function (value) {
              valueNames[value.id] = value.name
            })
            category.valueNames = valueNames
            return category
          }),
          feedbacks: $filter('orderBy')(feedback.remarks, '-created_at'),
          store: feedback.store,
          // utility
          hide: $mdDialog.hide,
          showComplete: $scope.showComplete,
          showMarkAsDone: $scope.showMarkAsDone,
          showUserContact: $scope.showUserContact,
          showDeleteButton: $scope.showDeleteButton,
          updateFeedbackEntry: function (entry, data) {
            var params = {
              customerId: $scope.filter.customerId,
              id: entry.id,
            }
            return StatisticService.updateFeedbackEntry(params, data).then(function (updatedValues) {
              return angular.extend(entry, updatedValues)
            })
          },
          printPdf: function (event) {
            printFeedbacks(this.locals)
          },
          deleteFeedbackEntry: function (event, feedback) {
            var confirm = $mdDialog
              .confirm()
              .title('Feedback löschen')
              .textContent('Den Feedback-Eintrag ' + $filter('date')(feedback.created_at, 'short') + ' löschen?')
              .ok('Ja, löschen')
              .cancel('Nein, abbrechen')
              .targetEvent(event)
            confirm._options.multiple = true
            $mdDialog.show(confirm).then(() => {
              var params = {
                customerId: $scope.filter.customerId,
                id: feedback.id,
              }
              return StatisticService.deleteFeedbackEntry(params).then(() => {
                $scope.load()
                var index = this.feedbacks.indexOf(feedback)
                if (index >= 0) {
                  this.feedbacks.splice(index, 1)
                }
              })
            })
          },
        },
        bindToController: true,
        controllerAs: '$ctrl',
        fullscreen: true,
      })
    }

    function printFeedbacks(options) {
      UNPKG.load('jspdf', '2.5.1', 'dist/jspdf.umd.min.js')
        .then(function () {
          var fileName = (options.showComplete ? 'Bewertungen' : 'Kommentare') + ' - '
          if (options.store.foreignIdent) {
            fileName += options.store.foreignIdent + ' - '
          }
          fileName += options.store.name + '.pdf'

          var marginX = 25
          var marginY = 25
          var pageFormat = 'a4'
          var pageWidth = 210
          var pageHeight = 297
          var pageOrient = 'portrait'
          var doc = jspdf.jsPDF({
            orientation: pageOrient,
            format: pageFormat,
            putOnlyUsedFonts: true,
          })

          var fontName = doc.getFont().fontName
          var fontStyle = doc.getFont().fontStyle
          var fontSize = 14
          var maxLineWidth = pageWidth - 2 * marginX
          var getStringWidth = function (string) {
            return doc.getFontSize() * doc.getStringUnitWidth(string) * (25.4 / 72)
          }
          var getLineHeight = function () {
            return doc.getFontSize() * doc.getLineHeightFactor() * (25.4 / 72)
          }
          var resetFont = function () {
            doc.setFont(fontName, fontStyle)
            doc.setFontSize(fontSize)
            doc.setTextColor(0)
          }

          options.feedbacks.forEach(function (feedback, i) {
            if (i > 0) {
              doc.addPage(pageFormat, pageOrient)
            }
            var page = 1
            var offsetY = 0

            // utils
            var writeText = function (text) {
              doc.splitTextToSize(text, maxLineWidth).forEach(function (line) {
                var offsetX = 0
                var currentFontName = doc.getFont().fontName
                var currentFontStyle = doc.getFont().fontStyle
                var currentFontSize = doc.getFontSize()
                var currentTextColor = doc.getTextColor()
                var factor = currentFontStyle == 'bold' ? 2.5 : 1
                // if text overflows
                if (offsetY + factor * getLineHeight() > pageHeight - 2 * marginY) {
                  // ... add new page
                  doc.addPage(pageFormat, pageOrient)
                  offsetY = 0
                  page++
                  // ... and write page number
                  resetFont()
                  doc.setFontSize(11).setTextColor(128)
                  writeText('#' + feedback.id + ' • Seite ' + page)
                  resetFont()
                  addGap(0.5)
                  doc
                    .setFont(currentFontName, currentFontStyle)
                    .setFontSize(currentFontSize)
                    .setTextColor(currentTextColor)
                }
                offsetY += getLineHeight()

                var writingSymbols = false
                var char
                var codePoint
                var part = ''
                var symbolCodePointStart = 9728
                for (char of line) {
                  codePoint = char.codePointAt(0)
                  if (codePoint >= symbolCodePointStart === writingSymbols) {
                    part += char
                    continue
                  }
                  // write part and switch font
                  doc.text(part, marginX + offsetX, marginY + offsetY)
                  offsetX += getStringWidth(part)
                  writingSymbols = codePoint >= symbolCodePointStart
                  if (writingSymbols) {
                    doc.setFont('Segoe UI Symbol', 'normal')
                  } else {
                    doc.setFont(currentFontName, currentFontStyle)
                  }
                  part = char
                  continue
                }
                // write remaining part
                doc.text(part, marginX + offsetX, marginY + offsetY)
                if (writingSymbols) {
                  doc.setFont(currentFontName, currentFontStyle)
                }
              })
            }
            var addGap = function (height) {
              offsetY += getLineHeight() * (height || 1)
            }

            // id
            doc.setFontSize(11).setTextColor(128)
            writeText('#' + feedback.id)
            resetFont()

            // user/author info
            var text = 'Erstellt am ' + $filter('date')(feedback.created_at, 'short')
            if (feedback.order_date) {
              text += ' • '
              text += 'Bestellt am ' + $filter('date')(feedback.order_date, 'shortDate')
            }
            if (feedback.user_email && options.showUserContact) {
              text += '\nKontakt: '
              if (feedback.user_firstname || feedback.user_lastname) {
                text += ((feedback.user_firstname || '') + ' ' + (feedback.user_lastname || '')).trim()
                text += ' • '
              }
              text += feedback.user_email
              if (feedback.user_telephone) {
                text += ' • '
                text += $filter('tel')(feedback.user_telephone)
              }
            }
            doc.setFontSize(11).setTextColor(128)
            writeText(text)
            resetFont()

            // attributes
            if (feedback.attributes) {
              options.categories.forEach(function (category) {
                addGap(0.5)
                if (category.title) {
                  doc.setFont(fontName, 'bold')
                  writeText(category.title)
                  resetFont()
                  addGap(0.5)
                }
                Object.entries(category.attributes).forEach(function (attrEntry) {
                  var attrId = attrEntry[0]
                  var attrName = attrEntry[1]
                  if (feedback.attributes[attrId] == null) {
                    return
                  }
                  writeText(attrName + ': ' + category.valueNames[feedback.attributes[attrId]])
                })
              })
            }

            // remark
            if (feedback.text) {
              addGap(0.5)
              if (options.showComplete) {
                doc.setFont(fontName, 'bold')
                writeText('Persönlicher Kommentar')
                resetFont()
                addGap(0.5)
              }
              writeText(decodeHtml(feedback.text))
              resetFont()
            }

            // categories
            if (feedback.categories && feedback.categories.length) {
              addGap(0.5)
              doc.setFont(fontName, 'bold')
              writeText('Bestellte Produkte')
              resetFont()
              addGap(0.5)
              var categories = feedback.categories.reduce(function (string, category) {
                return string + (string ? '\n' : '') + category
              })
              writeText(categories)
              resetFont()
            }
          })

          return doc.save(fileName)
        })
        .catch(function () {
          Notification.error('Fehler beim Generieren des PDFs')
        })
    }
  }

  function decodeHtml(html) {
    var txt = document.createElement('textarea')
    txt.innerHTML = html
    return txt.value
  }
})()
