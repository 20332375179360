;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.dpos', []).config(DPosConfig).controller('DPosController', DPosController)

  function DPosConfig($stateProvider, moduleRegisterProvider) {
    $stateProvider.state('dpos', {
      url: '/dpos',
      views: {
        '@': { templateUrl: 'src/dpos/views/layout.html' },
        'colMain@dpos': {
          templateUrl: 'src/dpos/views/dpos.html',
          controller: 'DPosController',
          controllerAs: 'vm',
        },
      },
    })

    moduleRegisterProvider.add({
      title: 'Kassensystem Status',
      defaultUrl: 'dpos',
      materialIcon: 'dvr',
      accessRoles: ['admin', 'servicecenter'],
    })
  }

  function DPosController(UserService, CustomerService) {
    var vm = this
    vm.loadDPosStatus = loadDPosStatus

    reset()
    initCustomerSelect()

    function initCustomerSelect() {
      CustomerService.getCustomers().$promise.then((response) => {
        vm.customers = response.filter((customer) => customer.active)
      })

      if (!vm.customerSelect) {
        vm.customerSelect = function (customerId) {
          vm.customerId = customerId
          reset()
          vm.loadDPosStatus()
        }
      }
    }

    function reset() {
      delete vm.data
    }

    function loadDPosStatus() {
      vm.loading = true
      CustomerService.getDPos(vm.customerId)
        .$promise.then((response) => {
          addColor4Status(response)
          vm.data = response
        })
        .catch(() => {
          vm.data = []
        })
        .finally(() => {
          vm.lastLoaded = new Date()
          vm.loading = false
        })
    }

    function addColor4Status(list) {
      list.forEach((entry) => {
        var color = 'grey-400'
        switch (entry.status) {
          case true:
            color = 'green'
            break
          case false:
            color = 'red'
            break
        }
        entry.color = { color }
      })
      return list
    }
  }
})()
